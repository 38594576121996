import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { ReturnIcon } from 'shared/components/ihcl/icon';
import { Input } from 'shared/components/ihcl/input';

const CustomCertInputWrapper = styled('div', ({ $theme }) => ({
  paddingTop: $theme.sizing.scale600,
  color: $theme.colors.gray,
}));

const popularCertificationValues = [
  'ACLS',
  'BLS',
  'CPR',
  'NRP',
  'PALS',
  'RN',
  'ONS/ONCC',
];

const stringToLabelValueObject = (str) => ({
  label: str,
  value: str,
});

const popularCertificationOptions = popularCertificationValues.map(
  stringToLabelValueObject
);

export const CertificationsEditor = ({
  certifications,
  updateCertifications,
}) => {
  const [, theme] = useStyletron();

  const [customCertificationText, setCustomCertificationText] = useState('');
  const [isAddingCertification, setIsAddingCertification] = useState(false);
  const [customCertificationOptions, setCustomCertificationOptions] = useState(
    certifications
      .filter(
        (certification) => !popularCertificationValues.includes(certification)
      )
      .map(stringToLabelValueObject)
  );
  const certificationOptions = popularCertificationOptions.concat(
    customCertificationOptions
  );
  const addCustomCertification = () => {
    if (
      !customCertificationOptions.some(
        (certificationOption) =>
          certificationOption.label === customCertificationText
      )
    ) {
      setCustomCertificationOptions([
        ...customCertificationOptions,
        {
          label: customCertificationText,
          value: customCertificationText,
        },
      ]);
    }
    if (!certifications.includes(customCertificationText)) {
      updateCertifications([...certifications, customCertificationText]);
    }
    setCustomCertificationText('');
    setIsAddingCertification(false);
  };

  return (
    <>
      <ButtonSelect
        multiSelect
        orientation="horizontal"
        options={certificationOptions}
        onChange={(value, option, remove) => {
          let newCertifications = certifications ? [...certifications] : [];
          if (remove) {
            newCertifications = newCertifications.filter(
              (certification) => certification !== value
            );
          } else {
            newCertifications.push(value);
          }
          updateCertifications(newCertifications);
        }}
        selectedOptions={certificationOptions.filter(
          (option) => certifications && certifications.includes(option.value)
        )}
        padding="minimal"
      />
      {!isAddingCertification && (
        <Button
          kind="minimal"
          onClick={() => {
            setIsAddingCertification(true);
          }}
        >
          + Add another certification
        </Button>
      )}
      {isAddingCertification && (
        <CustomCertInputWrapper>
          <Input
            autoFocus
            enterkeyhint="done"
            label="Enter your certification"
            value={customCertificationText}
            endEnhancer={
              <ReturnIcon
                onClick={addCustomCertification}
                color={theme.colors.gray}
              />
            }
            onKeyDown={(evt) => {
              if (evt.keyCode === 13) {
                if (!customCertificationText) {
                  return;
                }
                addCustomCertification();
              }
            }}
            onChange={(evt) =>
              setCustomCertificationText(evt.currentTarget.value)
            }
          />
        </CustomCertInputWrapper>
      )}
    </>
  );
};

CertificationsEditor.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateCertifications: PropTypes.func.isRequired,
};
