import { trackEvent } from 'shared/helpers/tracking';

import { syncPushToken, trackIterablePushOpen } from './notifications';

export const dispatch = (type, payload, minVersion) => {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type, payload, minVersion })
  );
};

export const receiveNativeMessage = (e) => {
  const message = e.data;

  if (
    !message ||
    !message.type ||
    /^webpack/.test(message.type) ||
    message.embedId // Typeform messages
  ) {
    return;
  }

  switch (message.type) {
    case 'trackEvent': {
      const { event, properties } = message.payload;
      trackEvent(event, properties);
      break;
    }

    case 'registerPushToken': // to support <= 1.4.0 native clients
    case 'syncPushToken': {
      const { token, deviceOS, deviceMetadata = null } = message.payload;
      syncPushToken(token, deviceOS, deviceMetadata);
      break;
    }

    case 'trackIterablePushOpen': {
      const { campaignId, messageId, templateId, dataFields } = message.payload;
      trackIterablePushOpen(campaignId, messageId, templateId, dataFields);
      break;
    }

    case 'appVersion': {
      const { appVersion } = message.payload;
      if (
        window.localStorage &&
        window.localStorage.getItem('nativeAppVersion') !== appVersion
      ) {
        window.localStorage.setItem('nativeAppVersion', appVersion);
      }

      mixpanel.register({ 'Native App Version': appVersion });

      break;
    }

    default: {
      const notify =
        // eslint-disable-next-line no-console, no-underscore-dangle
        window.__DEV__ ? console.warn : window.Sentry.captureMessage;

      notify(`Unsupported message: ${JSON.stringify(message)}`, {
        level: 'info',
        fingerprint: ['unsupported-message', message.type],
      });
    }
  }
};
