export function getCalendlyProps(
  talentProfile,
  { hideEventTypeDetails = false } = {}
) {
  return {
    url: `/talent_profile/${talentProfile.id}/calendly_screening_redirect`,
    prefill: {
      email: talentProfile.email,
      name: talentProfile.full_name,
      customAnswers: {
        // the phone input field is currently the 2nd
        a2: talentProfile.phone?.text,
      },
    },
    pageSettings: {
      hideEventTypeDetails,
      hideLandingPageDetails: true,
      hideGdprBanner: true,
    },
    utm: {
      // We use `utm_source` to link Calendly event confirmations (received via
      // webhook) to talent profiles
      utmSource: JSON.stringify({
        type: 'screening',
        talent_ref: talentProfile.referral_code,
        metadata: {
          scheduled_by: 'talent',
        },
      }),
    },
  };
}
