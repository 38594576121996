import React from 'react';
import PropTypes from 'prop-types';
import { withStyle } from 'shared/components/ihcl/styled';
import {
  Tag as BaseTag,
  StyledRoot as BaseStyledRoot,
  StyledText as BaseStyledText,
  SIZE as BASE_SIZE,
  VARIANT,
} from 'baseui/tag';

export const SIZE = {
  ...BASE_SIZE,
  tiny: 'tiny',
};

export { KIND } from 'baseui/tag';

export const StyledRoot = withStyle(
  BaseStyledRoot,
  ({ $containerWidth, $size, $variant, $theme, $margins, $borderRadius }) => {
    const borderColor = $theme.colors.primary10;
    const borderWidth =
      $variant === 'solid' ? 0 : $theme.borders.tagBorderWidth;
    const color =
      $variant === 'solid' ? $theme.colors.primary : $theme.colors.gray;
    const optionalStyles = {};
    if ($margins !== null) {
      const { marginTop, marginRight, marginBottom, marginLeft } = $margins;
      optionalStyles.marginTop = marginTop;
      optionalStyles.marginRight = marginRight;
      optionalStyles.marginBottom = marginBottom;
      optionalStyles.marginLeft = marginLeft;
    }
    if ($borderRadius && typeof $borderRadius === 'object') {
      const {
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
      } = $borderRadius;
      optionalStyles.borderTopLeftRadius = borderTopLeftRadius;
      optionalStyles.borderTopRightRadius = borderTopRightRadius;
      optionalStyles.borderBottomRightRadius = borderBottomRightRadius;
      optionalStyles.borderBottomLeftRadius = borderBottomLeftRadius;
    } else if (typeof $borderRadius === 'string') {
      optionalStyles.borderTopLeftRadius = $borderRadius;
      optionalStyles.borderTopRightRadius = $borderRadius;
      optionalStyles.borderBottomRightRadius = $borderRadius;
      optionalStyles.borderBottomLeftRadius = $borderRadius;
    }
    const sharedStyles = {
      borderTopWidth: borderWidth,
      borderRightWidth: borderWidth,
      borderBottomWidth: borderWidth,
      borderLeftWidth: borderWidth,
      borderTopColor: borderColor,
      borderRightColor: borderColor,
      borderBottomColor: borderColor,
      borderLeftColor: borderColor,
      color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      ...optionalStyles,
    };
    // This is temporary until the employer tag style is fixed to not be blue on blue
    const sharedStylesNoColor = { ...sharedStyles };
    delete sharedStylesNoColor.color;
    if ($containerWidth) {
      sharedStyles.width = '100%';
    }
    if ($size === SIZE.small) {
      return {
        ...sharedStylesNoColor,
        fontSize: $theme.typography.font200.fontSize,
        lineHeight: '19px',
      };
    }
    if ($size === SIZE.medium) {
      return {
        ...sharedStyles,
        fontSize: $theme.typography.font200.fontSize,
        lineHeight: '18px',
        height: '34px',
      };
    }
    if ($size === SIZE.tiny) {
      return {
        ...sharedStyles,
        fontSize: '12px',
        lineHeight: '15px',
        height: '24px',
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '4px',
        paddingBottom: '4px',
      };
    }
    return { ...sharedStylesNoColor };
  }
);

export const StyledText = withStyle(
  BaseStyledText,
  ({ $maxWidth, $textColor, $textStyle, $textAlign, $theme, $width }) => {
    const styles = {
      maxWidth: $maxWidth || $theme.sizing.scale3200,
      fontWeight: $textStyle === 'bold' ? 800 : 500,
    };
    if ($textColor) {
      styles.color = $textColor;
    }
    if ($textAlign) {
      styles.textAlign = $textAlign;
    }
    if ($width) {
      styles.width = $width;
    }
    return styles;
  }
);

export const StyledFadeInRoot = withStyle(StyledRoot, () => ({
  animationDuration: '0.5s',
  animationIterationCount: '1',
  animationName: {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
}));

// eslint-disable-next-line react/require-default-props
export const Tag = (props) => {
  const rootProps = {
    $borderRadius: props.$borderRadius,
    $containerWidth: props.$containerWidth,
    $size: props.size,
    $variant: props.variant,
    $margins: props.$margins,
    role: props.role,
    style: props.style,
    'aria-selected': props['aria-selected'],
  };
  const textProps = {
    $textStyle: props.$textStyle,
  };
  if (props.$textColor) {
    textProps.$textColor = props.$textColor;
  }
  if (props.$containerWidth) {
    textProps.$textAlign = 'center';
    textProps.$width = '100%';
  }
  if (props.$containerWidth || props.$fullWidth) {
    textProps.$maxWidth = 'unset';
  }
  const Root = props.$fadeIn ? StyledFadeInRoot : StyledRoot;
  return (
    <BaseTag
      {...props}
      overrides={{
        ...(props.overrides || {}),
        Root: {
          component: Root,
          props: rootProps,
        },
        Text: {
          component: StyledText,
          props: textProps,
        },
      }}
    />
  );
};

Tag.propTypes = {
  $borderRadius: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  $containerWidth: PropTypes.bool,
  $fadeIn: PropTypes.bool,
  $fullWidth: PropTypes.bool,
  $margins: PropTypes.object,
  $textColor: PropTypes.string,
  $textStyle: PropTypes.oneOf(['normal', 'bold']),
  'aria-selected': PropTypes.bool,
  overrides: PropTypes.object,
  role: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZE)),
  style: PropTypes.object,
  variant: PropTypes.oneOf(Object.keys(VARIANT)),
};
Tag.defaultProps = {
  $borderRadius: null,
  $containerWidth: false,
  $fadeIn: false,
  $fullWidth: false,
  $margins: null,
  $textColor: null,
  $textStyle: 'bold',
  'aria-selected': null,
  overrides: null,
  role: 'button',
  size: SIZE.small,
  style: {},
  variant: VARIANT.light,
};
