import React, { useEffect, useState } from 'react';

import { useStyletron } from 'shared/components/ihcl/styled';
import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { ANCHOR, Drawer } from 'shared/components/ihcl/drawer';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { TalentModal, ROLE, SIZE } from 'shared/components/ihcl/modal';
import { Spinner } from 'shared/components/ihcl/spinner';
import { Text } from 'shared/components/ihcl/text';
import { Textarea } from 'shared/components/ihcl/textarea';
import { doPost } from 'shared/helpers/http';
import { trackEvent } from 'shared/helpers/tracking';
import {
  ActionButtonWrapper,
  BottomButtonWrapper,
  Hideable,
  ResponsibilityTipsButtonWrapper,
  ResumeTipsWrapper,
  SectionContents,
  SectionTitleGroup,
  SimpleCard,
} from './TalentProfileResumeStyledElements';

const ResumeTips = ({ close }: { close: Function }) => {
  const [, theme] = useStyletron();
  const tips = [
    {
      title: 'Quantify your experience',
      description: (
        <>
          <Text variant="ParagraphMedium" color="bodyCopyGray">
            Instead of just listing your responsibilities with bullet points,
            convey your accomplishments using quantitative measurements when
            possible.
          </Text>
          <SimpleCard $backgroundColor={theme.colors.negative00}>
            <Text variant="ParagraphMedium" color="negative70">
              <LucideIcon
                name="ThumbsDown"
                style={{ marginRight: theme.sizing.unit16, flexShrink: 0 }}
                size="1.2em"
              />
            </Text>

            <Text variant="ParagraphMedium" color="negative70">
              Helped discharge patients
            </Text>
          </SimpleCard>
          <SimpleCard>
            <Text variant="ParagraphMedium" color="positive70">
              <LucideIcon
                name="ThumbsUp"
                style={{ marginRight: theme.sizing.unit16, flexShrink: 0 }}
                size="1.2em"
              />
            </Text>
            <Text variant="ParagraphMedium" color="positive70">
              Administered 10 discharges an hour during my shift. Provided ample
              health care education to patient and family members.
            </Text>
          </SimpleCard>
        </>
      ),
    },
    {
      title: 'Be descriptive',
      description: (
        <>
          <Text variant="ParagraphMedium" color="bodyCopyGray">
            When highlighting your experience, use strong action verbs such as
            “assisted” or “displayed.”
          </Text>
        </>
      ),
    },
    {
      title: 'Highlight transferable attributes',
      description: (
        <>
          <Text variant="ParagraphMedium" color="bodyCopyGray">
            In addition to being specific about your responsibilities,
            don&apos;t forget to highlight skills and attributes that can apply
            to any position. Examples include leadership skills, project
            development, and communication.
          </Text>
        </>
      ),
    },
  ];
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  return (
    <ResumeTipsWrapper>
      <Text variant="ParagraphLarge" color="black">
        <strong>{tips[currentTipIndex].title}</strong>
      </Text>
      {tips[currentTipIndex].description}
      <BottomButtonWrapper>
        {tips.length > 1 && (
          <Button
            color="alternate"
            disabled={currentTipIndex === tips.length - 1}
            onClick={() => {
              setCurrentTipIndex(currentTipIndex + 1);
              trackEvent(
                'talent.resume_editor_responsibilities_modal_tips.next'
              );
            }}
          >
            Next tip{' '}
            <LucideIcon
              name="ChevronRight"
              size="1.2em"
              style={{ marginLeft: theme.sizing.unit8 }}
            />
          </Button>
        )}
        <Button kind="tertiary" color="alternate" onClick={close}>
          <LucideIcon
            name="Reply"
            size="1.2em"
            style={{ marginRight: theme.sizing.unit8 }}
          />{' '}
          Back to editing
        </Button>
      </BottomButtonWrapper>
    </ResumeTipsWrapper>
  );
};

const TalentProfileResumeResponsibilitiesModal = ({
  isOpen,
  jobTitle,
  onAddHighlight,
  onClose,
  defaultResponsibilityText = null,
}: {
  isOpen: boolean;
  jobTitle: string;
  onAddHighlight: Function;
  onClose: Function;
  defaultResponsibilityText?: string;
}) => {
  const [suggestedResponsibilities, setSuggestedResponsibilities] =
    useState(null);
  const [
    fetchingSuggestedResponsibilities,
    setFetchingSuggestedResponsibilities,
  ] = useState(false);
  const [selectedSuggestionId, setSelectedSuggestionId] = useState(null);
  const [responsibilityText, setResponsibilityText] = useState(
    defaultResponsibilityText
  );
  const [showTips, setShowTips] = useState(false);
  const [, theme] = useStyletron();
  useEffect(() => {
    if (responsibilityText === null && defaultResponsibilityText !== null) {
      setResponsibilityText(defaultResponsibilityText);
    }
    if (
      defaultResponsibilityText === null &&
      responsibilityText === '' &&
      selectedSuggestionId === null
    ) {
      setResponsibilityText(null);
    }
  }, [defaultResponsibilityText, responsibilityText]);
  useEffect(() => {
    if (
      isOpen &&
      !suggestedResponsibilities &&
      !fetchingSuggestedResponsibilities &&
      responsibilityText === null &&
      defaultResponsibilityText === null
    ) {
      setFetchingSuggestedResponsibilities(true);
      doPost('/resume/generated_job_responsibilities', {
        job_title: jobTitle,
      })
        .then((res) => {
          setSuggestedResponsibilities(res);
        })
        .catch(() => {
          setSuggestedResponsibilities([]);
        })
        .finally(() => {
          setFetchingSuggestedResponsibilities(false);
        });
    }
  }, [
    isOpen,
    suggestedResponsibilities,
    fetchingSuggestedResponsibilities,
    responsibilityText,
  ]);
  const closeModal = (closeType) => {
    onClose();
    setResponsibilityText(null);
    setSuggestedResponsibilities(null);
    setSelectedSuggestionId(null);
    switch (closeType) {
      case 'add':
        trackEvent('talent.resume_editor_responsibilities_modal_edit.add', {
          'Job Title': jobTitle,
        });
        break;
      case 'cancel':
        trackEvent('talent.resume_editor_responsibilities_modal_edit.cancel', {
          'Job Title': jobTitle,
        });
        break;
      default:
        break;
    }
  };
  let contents = null;
  if (fetchingSuggestedResponsibilities) {
    contents = (
      <SimpleCard
        $backgroundColor={theme.colors.grayBackground}
        $margin={theme.sizing.unit16}
        $verticalLayout
      >
        <Spinner />
        <Text variant="ParagraphLarge">
          <strong>Generating highlights based off your job title.</strong>
        </Text>
      </SimpleCard>
    );
  }
  if (
    suggestedResponsibilities &&
    selectedSuggestionId === null &&
    responsibilityText === null
  ) {
    contents =
      suggestedResponsibilities.length > 0 ? (
        <>
          <ButtonSelect
            fillWidth
            selectedOptions={selectedSuggestionId}
            onChange={(option) => {
              setSelectedSuggestionId(option);
              const selectedResponsibility = suggestedResponsibilities.find(
                (responsibility) => responsibility.id === option
              );
              setResponsibilityText(selectedResponsibility.responsibility);
              trackEvent(
                'talent.resume_editor_responsibilities_modal_ai_duty.select',
                {
                  'Job Title': jobTitle,
                  'Selected Duty': selectedResponsibility.responsibility,
                }
              );
            }}
            options={suggestedResponsibilities.map((responsibility) => ({
              label: responsibility.responsibility,
              value: responsibility.id,
            }))}
          />
          <Button
            kind="minimal"
            fullWidth
            style={{ marginTop: theme.sizing.unit16 }}
            onClick={() => {
              setSelectedSuggestionId(-1);
              setResponsibilityText('');
              trackEvent(
                'talent.resume_editor_responsibilities_modal_ai_duty.manual',
                {
                  'Job Title': jobTitle,
                }
              );
            }}
          >
            Manually enter a responsibility
          </Button>
        </>
      ) : (
        <>
          <Text variant="ParagraphMedium" color="bodyCopyGray">
            Sorry, we couldn&apos;t generate any highlights for that job title.
          </Text>
          <Button
            kind="tertiary"
            style={{ marginTop: theme.sizing.unit16 }}
            onClick={() => {
              setSelectedSuggestionId(-1);
              setResponsibilityText('');
              trackEvent(
                'talent.resume_editor_responsibilities_modal_ai_duty_no_results.manual',
                {
                  'Job Title': jobTitle,
                }
              );
            }}
          >
            Manually enter a responsibility
          </Button>
        </>
      );
  }
  if (responsibilityText !== null) {
    contents = (
      <>
        <Textarea
          value={responsibilityText}
          onChange={(e) => setResponsibilityText(e.target.value)}
        />
        <ResponsibilityTipsButtonWrapper>
          <Button
            kind="tertiary"
            color="neutral"
            onClick={() => {
              setShowTips(true);
              trackEvent(
                'talent.resume_editor_responsibilities_modal_tips.show'
              );
            }}
          >
            Want some tips?
            <LucideIcon
              name="Lightbulb"
              style={{ marginLeft: theme.sizing.unit8 }}
            />
          </Button>
        </ResponsibilityTipsButtonWrapper>
        <Drawer
          isOpen={showTips}
          onClose={() => {
            setShowTips(false);
            trackEvent('talent.resume_editor_responsibilities_modal_tips.hide');
          }}
          anchor={ANCHOR.bottom}
          title="Resume writing tips"
        >
          <ResumeTips
            close={() => {
              setShowTips(false);
              trackEvent(
                'talent.resume_editor_responsibilities_modal_tips.hide'
              );
            }}
          />
        </Drawer>
      </>
    );
  }

  return (
    <TalentModal
      useTalentDialog={false}
      onClose={() => {}}
      closeable={false}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.full}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        Dialog: {
          style: {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            overflow: 'auto',
          },
        },
      }}
    >
      <ActionButtonWrapper>
        <Button
          data-testid="close-edit-modal-button"
          kind="outline"
          disabled={false}
          onClick={() => closeModal('cancel')}
        >
          Close
        </Button>
        <Hideable $hidden={responsibilityText === null}>
          <Button
            kind="tertiary"
            disabled={!responsibilityText}
            onClick={() => {
              onAddHighlight(responsibilityText);
              closeModal('add');
            }}
          >
            Add highlight
          </Button>
        </Hideable>
      </ActionButtonWrapper>
      {responsibilityText === null ? (
        <SectionContents>
          <SectionTitleGroup>
            <Text variant="HeadingSmall" color="black">
              Responsibilities and achievements
            </Text>
            <Text variant="ParagraphMedium" color="bodyCopyGray">
              Start by choosing a highlight to modify
            </Text>
          </SectionTitleGroup>
          {contents}
        </SectionContents>
      ) : (
        contents
      )}
    </TalentModal>
  );
};

export default TalentProfileResumeResponsibilitiesModal;
