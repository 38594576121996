import React, { createRef, useState } from 'react';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';
import {
  Input as BaseInput,
  StatefulContainer,
  StyledInput as BaseStyledInput,
  StyledInputContainer as BaseInputContainer,
  StyledInputEnhancer as BaseInputEnhancer,
  StyledRoot as BaseStyledInputRoot,
  ADJOINED,
} from 'baseui/input';
import { StyledMaskToggleButton as BaseStyledMaskToggleButton } from 'baseui/input/styled-components';
import PropTypes from 'prop-types';
import { cssValueMath } from './index';

import { HideIcon, ViewIcon } from './icon';

export const getInputFillColor = ({
  $error,
  $isFocused,
  $positive,
  $theme,
}) => {
  let inputFillColor = $isFocused
    ? $theme.colors.inputFillActive
    : $theme.colors.inputFill;
  if ($positive && !$isFocused) {
    inputFillColor = $theme.colors.inputFillPositive;
  }
  if ($error && !$isFocused) {
    inputFillColor = $theme.colors.inputFillError;
  }
  return inputFillColor;
};

export const getLabelColor = ({ $error, $isFocused, $positive, $theme }) => {
  if (!$theme || !$theme.colors) {
    console.error('$theme not found', { $theme });
    return 'black';
  }
  let color = $isFocused ? $theme.colors.primary60 : $theme.colors.gray;
  if ($positive && !$isFocused) {
    color = $theme.colors.positive;
  }
  if ($error && !$isFocused) {
    color = $theme.colors.negative70;
  }
  return color;
};

export const StyledInputEnhancer = withStyle(BaseInputEnhancer, ({ $size }) => {
  const styles = {
    backgroundColor: 'transparent',
  };
  if ($size === 'tiny') {
    styles.paddingLeft = 0;
  }
  return styles;
});

export const StyledMaskToggleButton = withStyle(
  BaseStyledMaskToggleButton,
  ({ $error, $isFocused, $positive, $theme }) => ({
    color: getLabelColor({ $error, $isFocused, $positive, $theme }),
  })
);

export const StyledInputContainer = withStyle(BaseInputContainer, () => ({
  backgroundColor: 'transparent',
  position: 'relative',
}));

export const Label = styled(
  'label',
  ({ $error, $isFocused, $positive, $size, $theme, $value }) => ({
    color: getLabelColor({ $error, $isFocused, $positive, $theme }),
    display: ['compact', 'tiny'].includes($size) && $value ? 'none' : 'block',
    backgroundColor: 'transparent',
    position: 'absolute',
    left:
      $size === 'compact'
        ? $theme.sizing.inputCompactPadding
        : $theme.sizing.inputPaddingLeft,
    top: 0,
    fontSize: ['compact', 'tiny'].includes($size) ? '12px' : '15px',
    lineHeight: ['compact', 'tiny'].includes($size) ? '12px' : '15px',
    paddingTop: '6px',
    fontWeight: 500,
  })
);

export const Caption = styled(
  'div',
  ({ $error, $isFocused, $positive, $collapseCaptionPadding, $theme }) => ({
    color: getLabelColor({ $error, $isFocused, $positive, $theme }),
    paddingLeft: cssValueMath(
      $theme.sizing.inputPaddingLeft,
      '+',
      $theme.borders.inputBorderWidth
    ),
    fontSize: '14px',
    lineHeight: '18px',
    paddingTop: $collapseCaptionPadding ? 0 : '8px',
  })
);

const StyledInputRoot = withStyle(
  BaseStyledInputRoot,
  ({ $error, $isFocused, $positive, $size, $theme }) => {
    let height = $theme.sizing.inputHeight;
    if ($size === 'compact') {
      height = $theme.sizing.inputCompactHeight;
    }
    if ($size === 'tiny') {
      height = $theme.sizing.inputTinyHeight;
    }
    const borderColors = $isFocused
      ? {
          borderTopColor: $theme.colors.primary,
          borderRightColor: $theme.colors.primary,
          borderBottomColor: $theme.colors.primary,
          borderLeftColor: $theme.colors.primary,
        }
      : {};
    return {
      backgroundColor: getInputFillColor({
        $theme,
        $error,
        $isFocused,
        $positive,
      }),
      ...borderColors,
      height,
    };
  }
);

export const StyledInput = withStyle(
  BaseStyledInput,
  ({ $error, $isFocused, $positive, $theme, label, size, type, value }) => {
    let paddingTop = $theme.sizing.inputPaddingTop;
    if (!label) {
      paddingTop = '10px';
    }
    if (size === 'compact') {
      paddingTop = $theme.sizing.inputCompactPaddingTop;
      if (!label) {
        paddingTop = '6px';
      }
    }
    if (size === 'tiny') {
      paddingTop = $theme.sizing.inputTinyPaddingTop;
    }
    let placeHolderTop = '12px';
    if (size === 'compact') {
      placeHolderTop = '7px';
    }
    if (size === 'tiny') {
      placeHolderTop = '3px';
    }
    const additionalStyles = {};
    if (type === 'number') {
      const spinButtonStyles = {
        '-webkit-appearance': 'none',
        margin: 0,
      };
      additionalStyles['::-webkit-inner-spin-button'] = spinButtonStyles;
      additionalStyles['::-webkit-outer-spin-button'] = spinButtonStyles;
    }
    if (type === 'month') {
      additionalStyles['-webkit-appearance'] = 'none';
      additionalStyles['::-webkit-date-and-time-value'] = {
        textAlign: 'start',
      };
    }
    let focusLabelDisplay = 'block';
    let focusLabelTop = 0;
    if (size === 'compact') {
      focusLabelDisplay = 'none';
    }
    if (size === 'tiny') {
      if (value && value.length) {
        focusLabelDisplay = 'none';
      } else {
        focusLabelTop = placeHolderTop;
      }
    }
    return {
      backgroundColor: getInputFillColor({
        $theme,
        $error,
        $isFocused,
        $positive,
      }),
      paddingTop,
      paddingLeft: $theme.sizing.inputPaddingLeft,
      position: 'relative',
      ':empty+label': {
        display: 'block',
      },
      ':placeholder-shown+label': {
        top: placeHolderTop,
        fontSize: size === 'compact' ? '12px' : '15px',
        display: 'block',
      },
      ':not(:placeholder-shown)+label': {
        display: size === 'tiny' ? 'none' : 'block',
      },
      ':focus+label': {
        display: focusLabelDisplay,
        fontSize: '15px',
        transition: '200ms all linear',
        top: focusLabelTop,
      },
      ...additionalStyles,
    };
  }
);

const StyledCompactInput = withStyle(StyledInput, ({ $theme }) => ({
  paddingLeft: $theme.sizing.inputCompactPadding,
  paddingRight: $theme.sizing.inputCompactPadding,
}));

const StyledTinyInput = withStyle(StyledInput, ({ $theme }) => ({
  fontSize: '16px',
  paddingLeft: $theme.sizing.inputTinyPadding,
  paddingRight: 0,
}));

const getDefaultInputId = () => `input-${Math.random().toString(36).slice(5)}`;

const TinyLabel = styled('div', ({ $theme }) => ({
  color: $theme.colors.grayPlaceholder,
  fontSize: '12px',
  whiteSpace: 'nowrap',
}));

const LabeledInput = React.forwardRef(
  ({ $value, caption, endEnhancer, id, ...props }, inputRef) => {
    let StyledInputComponent = StyledInput;

    if (props.size === 'compact') {
      StyledInputComponent = StyledCompactInput;
    }
    if (props.size === 'tiny') {
      StyledInputComponent = StyledTinyInput;
    }
    return (
      <>
        <StyledInputComponent {...props} id={id} ref={inputRef} />
        <Label
          htmlFor={id}
          $error={props.$error}
          $isFocused={props.$isFocused}
          $positive={props.$positive}
          $size={props.size}
          $value={$value}
        >
          {props.label}
        </Label>
      </>
    );
  }
);
LabeledInput.defaultProps = {
  $error: false,
  $isFocused: false,
  $positive: false,
  $value: '',
  caption: null,
  endEnhancer: null,
  label: '',
  size: 'default',
};
LabeledInput.propTypes = {
  $error: PropTypes.bool,
  $isFocused: PropTypes.bool,
  $positive: PropTypes.bool,
  $value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caption: PropTypes.node,
  endEnhancer: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  size: PropTypes.oneOf(['default', 'compact', 'tiny']),
};

export const Input = React.forwardRef(
  (
    {
      $isFocused,
      caption,
      collapseCaptionPadding,
      endEnhancer,
      error,
      instanceId,
      label,
      onBlur,
      positive,
      size,
      startEnhancer,
      style,
      tinyLabel,
      ...props
    },
    inputRef
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [focus, setFocus] = useState($isFocused);
    const [, theme] = useStyletron();
    const passwordMaskToggleIconSize = '25px';
    const hasLabel = label !== null && label.trim() !== '';
    let { type } = props;
    let fallbackRef = null;
    if (!inputRef) {
      fallbackRef = createRef();
    }
    if (type === 'password' && showPassword) {
      type = 'text';
    }
    const sharedProps = {
      $hasTinyLabel: Boolean(tinyLabel),
      $isFocused: focus,
      $error: error,
      $positive: positive,
      $size: size,
    };
    if (startEnhancer) {
      sharedProps.$adjoined = ADJOINED.left;
    }
    let endEnhancerProp = endEnhancer;
    if (
      size === 'tiny' &&
      (tinyLabel || label) &&
      !endEnhancer &&
      (props.value || props.value.length)
    ) {
      endEnhancerProp = (
        <TinyLabel
          onClick={() => {
            if (inputRef && inputRef.current) {
              inputRef.current.focus();
            }
            if (fallbackRef && fallbackRef.current) {
              fallbackRef.current.focus();
            }
          }}
        >
          {tinyLabel || label}
        </TinyLabel>
      );
    }
    return (
      <>
        <BaseInput
          startEnhancer={startEnhancer}
          endEnhancer={endEnhancerProp}
          onFocus={() => {
            if (!focus) {
              setFocus(true);
            }
          }}
          onBlur={(evt) => {
            if (focus && !$isFocused) {
              setFocus(false);
            }
            onBlur(evt);
          }}
          {...props}
          overrides={{
            Root: {
              component: StyledInputRoot,
              props: {
                ...sharedProps,
                style,
              },
            },
            StartEnhancer: StyledInputEnhancer,
            EndEnhancer: StyledInputEnhancer,
            InputContainer: StyledInputContainer,
            Input: {
              component: LabeledInput,
              props: {
                ...props,
                ...sharedProps,
                $value: props.value,
                label,
                ref: inputRef || fallbackRef,
                placeholder: focus || !hasLabel ? props.placeholder : ' ',
                id: props.id ? props.id : getDefaultInputId(),
                type,
                size,
                ...(instanceId && { 'data-instance-id': instanceId }),
              },
            },
            MaskToggleButton: {
              // eslint-disable-next-line react/no-unstable-nested-components
              component: () => (
                <StyledMaskToggleButton
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                  $error={error}
                  $isFocused={focus}
                  $positive={positive}
                  $theme={theme}
                >
                  {showPassword && (
                    <HideIcon size={passwordMaskToggleIconSize} />
                  )}
                  {!showPassword && (
                    <ViewIcon size={passwordMaskToggleIconSize} />
                  )}
                </StyledMaskToggleButton>
              ),
            },
          }}
        />
        {caption && (
          <Caption
            $error={error}
            $isFocused={focus}
            $positive={positive}
            $collapseCaptionPadding={collapseCaptionPadding}
          >
            {caption}
          </Caption>
        )}
      </>
    );
  }
);
Input.defaultProps = {
  $isFocused: false,
  caption: null,
  collapseCaptionPadding: false,
  endEnhancer: null,
  error: false,
  id: null,
  instanceId: null,
  label: '',
  onBlur: () => {},
  placeholder: ' ',
  positive: false,
  size: 'default',
  startEnhancer: null,
  style: {},
  tinyLabel: null,
  type: 'text',
  value: '',
};
Input.propTypes = {
  $isFocused: PropTypes.bool,
  caption: PropTypes.node,
  collapseCaptionPadding: PropTypes.bool,
  endEnhancer: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  error: PropTypes.bool,
  id: PropTypes.string,
  instanceId: PropTypes.string,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  positive: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'compact', 'tiny']),
  startEnhancer: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  style: PropTypes.object,
  tinyLabel: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const StatefulInput = React.forwardRef(
  ({ caption, ...props }, inputRef) => (
    <StatefulContainer {...props}>
      {(childrenProps) => (
        <Input caption={caption} {...childrenProps} ref={inputRef} />
      )}
    </StatefulContainer>
  )
);
StatefulInput.defaultProps = {
  $isFocused: false,
  caption: null,
  collapseCaptionPadding: false,
  endEnhancer: null,
  error: false,
  id: null,
  label: '',
  positive: false,
  type: 'text',
};
StatefulInput.propTypes = {
  $isFocused: PropTypes.bool,
  caption: PropTypes.node,
  collapseCaptionPadding: PropTypes.bool,
  endEnhancer: PropTypes.node,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  positive: PropTypes.bool,
  type: PropTypes.string,
};
