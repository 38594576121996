import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SharedPopUpManager, {
  PopUpManagerProps as SharedPopUpManagerProps,
} from 'shared/components/PopUpManager';

import { getHasScheduledInterviewToday } from 'talent/selectors/interviewSelectors';

export const PopUpManager = ({
  popUps,
  softSuppress,
  ...props
}: SharedPopUpManagerProps) => (
  <SharedPopUpManager popUps={popUps} softSuppress={softSuppress} {...props} />
);

PopUpManager.propTypes = {
  popUps: PropTypes.array.isRequired,
  softSuppress: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  softSuppress: ownProps.softSuppress || getHasScheduledInterviewToday(state),
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(
  PopUpManager
);
