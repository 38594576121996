import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-inlinesvg';

import answerIconPath from 'images/icons/advice/answer.svg';
import filterPath from 'images/icons/advice/filter.svg';
import followIconPath from 'images/icons/advice/follow.svg';
import askQuestionPath from 'images/icons/advice/pencil.svg';
import searchPath from 'images/icons/advice/search.svg';
import unfollowIconPath from 'images/icons/advice/unfollow.svg';
import alertIconPath from 'images/icons/alertCircle.svg';
import alertTriangleIconPath from 'images/icons/alertTriangle.svg';
import appIconPath from 'images/icons/app-icon.svg';
import archivePath from 'images/icons/archive-icon.svg';
import arrowSendPath from 'images/icons/arrow-send.svg';
import arrowUpNarrowWideFourPath from 'images/icons/arrow-up-narrow-wide-four.svg';
import arrowUpNarrowWideTwoPath from 'images/icons/arrow-up-narrow-wide-two.svg';
import awardIconPath from 'images/icons/award.svg';
import backArrowThinPath from 'images/icons/back-arrow-thin.svg';
import bookOpenCheckPath from 'images/icons/book-open-check.svg';
import briefcasePath from 'images/icons/briefcase.svg';
import businessDealPath from 'images/icons/business-deal.svg';
import calendarCheckIconPath from 'images/icons/calendar-check.svg';
import calendarClockIconPath from 'images/icons/calendar-clock.svg';
import calendarHeartIconPath from 'images/icons/calendar-heart.svg';
import calendarIconPath from 'images/icons/calendar-simple.svg';
import callIconPath from 'images/icons/call.svg';
import ceIconPath from 'images/icons/ce.svg';
import chainLinkPath from 'images/icons/chain-link.svg';
import checkBadgePath from 'images/icons/check-badge.svg';
import checkIconPath from 'images/icons/check.svg';
import clockPath from 'images/icons/clock.svg';
import closeCirlcePath from 'images/icons/close-circle.svg';
import closeIconPath from 'images/icons/close-currentColor.svg';
import closePaddedIconPath from 'images/icons/close-padded.svg';
import communityIconPath from 'images/icons/community.svg';
import cornerArrowRightPath from 'images/icons/corner-arrow-right.svg';
import diamondIconPath from 'images/icons/diamond.svg';
import dotPath from 'images/icons/dot.svg';
import downThinPath from 'images/icons/down-thin.svg';
import downPath from 'images/icons/down.svg';
import envelopePath from 'images/icons/envelope.svg';
import facilityPath from 'images/icons/facility.svg';
import filePath from 'images/icons/file-24px.svg';
import firstAidPath from 'images/icons/first-aid.svg';
import glassesIconPath from 'images/icons/glasses.svg';
import graduationCapIconPath from 'images/icons/graduation-cap.svg';
import heartIconPath from 'images/icons/heart.svg';
import hideIconPath from 'images/icons/hide.svg';
import homeSimpleIconPath from 'images/icons/home-simple.svg';
import homeIconPath from 'images/icons/home.svg';
import hospitalBedPath from 'images/icons/hospital-bed.svg';
import hospitalHousePath from 'images/icons/hospital-house.svg';
import hospitalPhonePath from 'images/icons/hospital-phone.svg';
import hospitalPath from 'images/icons/hospital.svg';
import infoIconPath from 'images/icons/info.svg';
import interviewIconPath from 'images/icons/interview.svg';
import arrowLeft from 'images/icons/left-arrow.svg';
import lightningIconPath from 'images/icons/lightning.svg';
import locationPinThinIconPath from 'images/icons/location-pin-thin.svg';
import locationPinIconPath from 'images/icons/location-pin.svg';
import lockIconPath from 'images/icons/lock.svg';
import logoIconPath from 'images/icons/logo.svg';
import maximizePath from 'images/icons/maximize.svg';
import messageBubblePath from 'images/icons/message-bubble-rounded-corners.svg';
import minimizePath from 'images/icons/minimize.svg';
import minusPath from 'images/icons/minus.svg';
import moneyBagPath from 'images/icons/money-bag.svg';
import moneyBagsPath from 'images/icons/money-bags.svg';
import moneyBillPath from 'images/icons/money-bill.svg';
import moveDownPath from 'images/icons/move-down.svg';
import newWindowPath from 'images/icons/new-window.svg';
import nextPath from 'images/icons/next.svg';
import nursePath from 'images/icons/nurse.svg';
import openEnvelopePath from 'images/icons/open-envelope.svg';
import paperAirplanePath from 'images/icons/paper-airplane.svg';
import paperclipPath from 'images/icons/paperclip.svg';
import pencilPath from 'images/icons/pencil-simple.svg';
import performanceUserGraph from 'images/icons/performance-user-graph.svg';
import phoneAutoDialPath from 'images/icons/phone-auto-dial.svg';
import piggyBankIconPath from 'images/icons/piggy-bank.svg';
import pinOutlinePath from 'images/icons/pin-outline.svg';
import pinPath from 'images/icons/pin.svg';
import plusPath from 'images/icons/plus.svg';
import previousPath from 'images/icons/previous.svg';
import profileIconPath from 'images/icons/profile.svg';
import progressOnePath from 'images/icons/progress-1.svg';
import progressTwoPath from 'images/icons/progress-2.svg';
import progressThreePath from 'images/icons/progress-3.svg';
import progressFourPath from 'images/icons/progress-4.svg';
import progressEmptyPath from 'images/icons/progress-empty.svg';
import refreshPath from 'images/icons/refresh.svg';
import replyPath from 'images/icons/reply.svg';
import returnPath from 'images/icons/return.svg';
import arrowRightThin from 'images/icons/right-arrow-thin.svg';
import arrowRight from 'images/icons/right-arrow.svg';
import colorSwatchGrapePath from 'images/icons/color-swatch-grape.svg';
import colorSwatchKalePath from 'images/icons/color-swatch-kale.svg';
import colorSwatchOceanPath from 'images/icons/color-swatch-ocean.svg';
import colorSwatchRustPath from 'images/icons/color-swatch-rust.svg';
import colorSwatchSlatePath from 'images/icons/color-swatch-slate.svg';
import rightPath from 'images/icons/right.svg';
import rocketPath from 'images/icons/rocket.svg';
import scrollIconPath from 'images/icons/scroll.svg';
import sendDocumentPath from 'images/icons/send-document.svg';
import settingsPath from 'images/icons/settings.svg';
import shieldCheckIconPath from 'images/icons/shield-check.svg';
import spinnerDarkPath from 'images/icons/spinner-dark.svg';
import sproutIconPath from 'images/icons/sprout.svg';
import squareXIconPath from 'images/icons/square-x.svg';
import stairsAscendPath from 'images/icons/stairs-person-ascend.svg';
import starIconPath from 'images/icons/star.svg';
import ticketPath from 'images/icons/ticket.svg';
import timeSensitiveMailRejectPath from 'images/icons/time-sensitive-mail-reject.svg';
import timeSensitiveMailPath from 'images/icons/time-sensitive-mail.svg';
import trashPath from 'images/icons/trash.svg';
import truckPath from 'images/icons/truck.svg';
import upPath from 'images/icons/up.svg';
import uploadPath from 'images/icons/upload.svg';
import usersPath from 'images/icons/users.svg';
import viewIconPath from 'images/icons/view.svg';
import walletPath from 'images/icons/wallet.svg';
import wandPath from 'images/icons/wand.svg';
import xCircleIconPath from 'images/icons/x-circle.svg';
import xIconPath from 'images/icons/x.svg';
import logoPath from 'images/ih-logo.svg';

import clockFourPath from 'images/icons/clock-4.svg';
import moonPath from 'images/icons/moon.svg';
import sunMoonPath from 'images/icons/sun-moon.svg';
import sunPath from 'images/icons/sun.svg';

export const Icon = ({ title, src, ...props }) => (
  <SVG src={src} title={title} {...props} />
);
Icon.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/require-default-props
export const AlertTriangleIcon = ({ title, ...props }) => (
  <Icon src={alertTriangleIconPath} title={title} {...props} />
);
AlertTriangleIcon.propTypes = {
  title: PropTypes.string,
};
AlertTriangleIcon.defaultProps = {
  title: 'Alert',
};

// eslint-disable-next-line react/require-default-props
export const AlertIcon = ({ title, ...props }) => (
  <Icon src={alertIconPath} title={title} {...props} />
);
AlertIcon.propTypes = {
  title: PropTypes.string,
};
AlertIcon.defaultProps = {
  title: 'Alert',
};

// eslint-disable-next-line react/require-default-props
export const AnswerIcon = ({ title, ...props }) => (
  <Icon src={answerIconPath} title={title} {...props} />
);
AnswerIcon.propTypes = {
  title: PropTypes.string,
};
AnswerIcon.defaultProps = {
  title: 'Answer',
};

// eslint-disable-next-line react/require-default-props
export const AppIcon = ({ title, ...props }) => (
  <Icon src={appIconPath} title={title} {...props} />
);
AppIcon.propTypes = {
  title: PropTypes.string,
};
AppIcon.defaultProps = {
  title: 'Incredible Health',
};

// eslint-disable-next-line react/require-default-props
export const ArchiveIcon = ({ title, ...props }) => (
  <Icon src={archivePath} title={title} {...props} />
);
ArchiveIcon.propTypes = {
  title: PropTypes.string,
};
ArchiveIcon.defaultProps = {
  title: 'Archive',
};

// eslint-disable-next-line react/require-default-props
export const ArrowLeftIcon = ({ title, ...props }) => (
  <Icon src={arrowLeft} title={title} {...props} />
);
ArrowLeftIcon.propTypes = {
  title: PropTypes.string,
};
ArrowLeftIcon.defaultProps = {
  title: 'Arrow Pointing Left',
};

// eslint-disable-next-line react/require-default-props
export const ArrowRightIcon = ({ title, ...props }) => (
  <Icon src={arrowRight} title={title} {...props} />
);
ArrowRightIcon.propTypes = {
  title: PropTypes.string,
};
ArrowRightIcon.defaultProps = {
  title: 'Arrow Pointing Right',
};

// eslint-disable-next-line react/require-default-props
export const ArrowRightThinIcon = ({ title, ...props }) => (
  <Icon src={arrowRightThin} title={title} {...props} />
);
ArrowRightThinIcon.propTypes = {
  title: PropTypes.string,
};
ArrowRightThinIcon.defaultProps = {
  title: 'Thin Arrow Pointing Right',
};

// eslint-disable-next-line react/require-default-props
export const ArrowSendIcon = ({ title, ...props }) => (
  <Icon src={arrowSendPath} title={title} {...props} />
);
ArrowSendIcon.propTypes = {
  title: PropTypes.string,
};
ArrowSendIcon.defaultProps = {
  title: 'Send',
};

// eslint-disable-next-line react/require-default-props
export const ArrowUpNarrowWideTwoIcon = ({ title, ...props }) => (
  <Icon src={arrowUpNarrowWideTwoPath} title={title} {...props} />
);
ArrowUpNarrowWideTwoIcon.propTypes = {
  title: PropTypes.string,
};
ArrowUpNarrowWideTwoIcon.defaultProps = {
  title: 'Arrow Up Narrow Wide Two',
};

// eslint-disable-next-line react/require-default-props
export const ArrowUpNarrowWideFourIcon = ({ title, ...props }) => (
  <Icon src={arrowUpNarrowWideFourPath} title={title} {...props} />
);
ArrowUpNarrowWideFourIcon.propTypes = {
  title: PropTypes.string,
};
ArrowUpNarrowWideFourIcon.defaultProps = {
  title: 'Arrow Up Narrow Wide Four',
};

// eslint-disable-next-line react/require-default-props
export const AwardIcon = ({ title, ...props }) => (
  <Icon src={awardIconPath} title={title} {...props} />
);
AwardIcon.propTypes = {
  title: PropTypes.string,
};
AwardIcon.defaultProps = {
  title: 'Award',
};

// eslint-disable-next-line react/require-default-props
export const AskQuestionIcon = ({ title, ...props }) => (
  <Icon src={askQuestionPath} title={title} {...props} />
);
AskQuestionIcon.propTypes = {
  title: PropTypes.string,
};
AskQuestionIcon.defaultProps = {
  title: 'Ask',
};

// eslint-disable-next-line react/require-default-props
export const BackArrowThinIcon = ({ title, ...props }) => (
  <Icon src={backArrowThinPath} title={title} {...props} />
);
BackArrowThinIcon.propTypes = {
  title: PropTypes.string,
};
BackArrowThinIcon.defaultProps = {
  title: 'Back',
};

// eslint-disable-next-line react/require-default-props
export const BookOpenCheckIcon = ({ title, ...props }) => (
  <Icon src={bookOpenCheckPath} title={title} {...props} />
);
BookOpenCheckIcon.propTypes = {
  title: PropTypes.string,
};
BookOpenCheckIcon.defaultProps = {
  title: 'Book Open Check',
};

// eslint-disable-next-line react/require-default-props
export const BriefcaseIcon = ({ title, ...props }) => (
  <Icon src={briefcasePath} title={title} {...props} />
);
BriefcaseIcon.propTypes = {
  title: PropTypes.string,
};
BriefcaseIcon.defaultProps = {
  title: 'Briefcase',
};

// eslint-disable-next-line react/require-default-props
export const BusinessDealIcon = ({ title, ...props }) => (
  <Icon src={businessDealPath} title={title} {...props} />
);
BusinessDealIcon.propTypes = {
  title: PropTypes.string,
};
BusinessDealIcon.defaultProps = {
  title: 'Business Deal',
};

// eslint-disable-next-line react/require-default-props
export const CalendarIcon = ({ title, ...props }) => (
  <Icon src={calendarIconPath} title={title} {...props} />
);
CalendarIcon.propTypes = {
  title: PropTypes.string,
};
CalendarIcon.defaultProps = {
  title: 'Calendar',
};

// eslint-disable-next-line react/require-default-props
export const ChainLinkIcon = ({ title, ...props }) => (
  <Icon src={chainLinkPath} title={title} {...props} />
);

ChainLinkIcon.propTypes = {
  title: PropTypes.string,
};
ChainLinkIcon.defaultProps = {
  title: 'Chain Link',
};

// eslint-disable-next-line react/require-default-props
export const CalendarCheckIcon = ({ title, ...props }) => (
  <Icon src={calendarCheckIconPath} title={title} {...props} />
);
CalendarCheckIcon.propTypes = {
  title: PropTypes.string,
};
CalendarCheckIcon.defaultProps = {
  title: 'Calendar Check',
};

// eslint-disable-next-line react/require-default-props
export const CalendarClockIcon = ({ title, ...props }) => (
  <Icon src={calendarClockIconPath} title={title} {...props} />
);
CalendarClockIcon.propTypes = {
  title: PropTypes.string,
};
CalendarClockIcon.defaultProps = {
  title: 'Calendar Clock',
};

// eslint-disable-next-line react/require-default-props
export const CalendarHeartIcon = ({ title, ...props }) => (
  <Icon src={calendarHeartIconPath} title={title} {...props} />
);
CalendarHeartIcon.propTypes = {
  title: PropTypes.string,
};
CalendarHeartIcon.defaultProps = {
  title: 'Calendar Heart',
};

// eslint-disable-next-line react/require-default-props
export const CheckBadge = ({ title, ...props }) => (
  <Icon src={checkBadgePath} title={title} {...props} />
);

CheckBadge.propTypes = {
  title: PropTypes.string,
};
CheckBadge.defaultProps = {
  title: 'Check Badge',
};

// eslint-disable-next-line react/require-default-props
export const CheckIcon = ({ title, ...props }) => (
  <Icon src={checkIconPath} title={title} {...props} />
);
CheckIcon.propTypes = {
  title: PropTypes.string,
};
CheckIcon.defaultProps = {
  title: 'Check',
};

// eslint-disable-next-line react/require-default-props
export const ContinuingEducationIcon = ({ title, ...props }) => (
  <Icon src={ceIconPath} title={title} {...props} />
);
ContinuingEducationIcon.propTypes = {
  title: PropTypes.string,
};
ContinuingEducationIcon.defaultProps = {
  title: 'Continuing Education',
};

// eslint-disable-next-line react/require-default-props
export const CloseIcon = ({ title, ...props }) => (
  <Icon src={closeIconPath} title={title} {...props} />
);
CloseIcon.propTypes = {
  title: PropTypes.string,
};
CloseIcon.defaultProps = {
  title: 'Close',
};

// eslint-disable-next-line react/require-default-props
export const ClosePaddedIcon = ({ title, ...props }) => (
  <Icon src={closePaddedIconPath} title={title} {...props} />
);
ClosePaddedIcon.propTypes = {
  title: PropTypes.string,
};
ClosePaddedIcon.defaultProps = {
  title: 'Close',
};

// eslint-disable-next-line react/require-default-props
export const ClockIcon = ({ title, ...props }) => (
  <Icon src={clockPath} title={title} {...props} />
);
ClockIcon.propTypes = {
  title: PropTypes.string,
};
ClockIcon.defaultProps = {
  title: 'Clock',
};

// eslint-disable-next-line react/require-default-props
export const ClockFourIcon = ({ title, ...props }) => (
  <Icon src={clockFourPath} title={title} {...props} />
);
ClockFourIcon.propTypes = {
  title: PropTypes.string,
};
ClockFourIcon.defaultProps = {
  title: 'Clock',
};

// eslint-disable-next-line react/require-default-props
export const CloseIconCircle = ({ title, ...props }) => (
  <Icon src={closeCirlcePath} title={title} {...props} />
);
CloseIconCircle.propTypes = {
  title: PropTypes.string,
};
CloseIconCircle.defaultProps = {
  title: 'Close',
};

// eslint-disable-next-line react/require-default-props
export const CommunityIcon = ({ title, ...props }) => (
  <Icon src={communityIconPath} title={title} {...props} />
);
CommunityIcon.propTypes = {
  title: PropTypes.string,
};
CommunityIcon.defaultProps = {
  title: 'Community',
};

// eslint-disable-next-line react/require-default-props
export const CornerArrowRightIcon = ({ title, ...props }) => (
  <Icon src={cornerArrowRightPath} title={title} {...props} />
);
CornerArrowRightIcon.propTypes = {
  title: PropTypes.string,
};
CornerArrowRightIcon.defaultProps = {
  title: 'Corner arrow right',
};

// eslint-disable-next-line react/require-default-props
export const DiamondIcon = ({ title, ...props }) => (
  <Icon src={diamondIconPath} title={title} {...props} />
);
DiamondIcon.propTypes = {
  title: PropTypes.string,
};
DiamondIcon.defaultProps = {
  title: 'Diamond',
};

// eslint-disable-next-line react/require-default-props
export const DotIcon = ({ title, ...props }) => (
  <Icon src={dotPath} title={title} {...props} />
);
DotIcon.propTypes = {
  title: PropTypes.string,
};
DotIcon.defaultProps = {
  title: 'Dot',
};

// eslint-disable-next-line react/require-default-props
export const DownIcon = ({ title, ...props }) => (
  <Icon src={downPath} title={title} {...props} />
);
DownIcon.propTypes = {
  title: PropTypes.string,
};
DownIcon.defaultProps = {
  title: 'Down',
};

// eslint-disable-next-line react/require-default-props
export const DownThinIcon = ({ title, ...props }) => (
  <Icon src={downThinPath} title={title} {...props} />
);
DownThinIcon.propTypes = {
  title: PropTypes.string,
};
DownThinIcon.defaultProps = {
  title: 'Down',
};

// eslint-disable-next-line react/require-default-props
export const EnvelopeIcon = ({ title, ...props }) => (
  <Icon src={envelopePath} title={title} {...props} />
);
EnvelopeIcon.propTypes = {
  title: PropTypes.string,
};
EnvelopeIcon.defaultProps = {
  title: 'Envelope',
};

// eslint-disable-next-line react/require-default-props
export const FacilityIcon = ({ title, ...props }) => (
  <Icon src={facilityPath} title={title} {...props} />
);
FacilityIcon.propTypes = {
  title: PropTypes.string,
};
FacilityIcon.defaultProps = {
  title: 'Facility',
};

// eslint-disable-next-line react/require-default-props
export const FileIcon = ({ title, ...props }) => (
  <Icon src={filePath} title={title} {...props} />
);
FileIcon.propTypes = {
  title: PropTypes.string,
};
FileIcon.defaultProps = {
  title: 'File',
};

// eslint-disable-next-line react/require-default-props
export const FilterIcon = ({ title, ...props }) => (
  <Icon src={filterPath} title={title} {...props} />
);
FilterIcon.propTypes = {
  title: PropTypes.string,
};
FilterIcon.defaultProps = {
  title: 'Filter',
};

// eslint-disable-next-line react/require-default-props
export const FirstAidIcon = ({ title, ...props }) => (
  <Icon src={firstAidPath} title={title} {...props} />
);
FirstAidIcon.propTypes = {
  title: PropTypes.string,
};
FirstAidIcon.defaultProps = {
  title: 'First Aid',
};

// eslint-disable-next-line react/require-default-props
export const FollowIcon = ({ title, ...props }) => (
  <Icon src={followIconPath} title={title} {...props} />
);
FollowIcon.propTypes = {
  title: PropTypes.string,
};
FollowIcon.defaultProps = {
  title: 'Follow',
};

// eslint-disable-next-line react/require-default-props
export const GlassesIcon = ({ title, ...props }) => (
  <Icon src={glassesIconPath} title={title} {...props} />
);
GlassesIcon.propTypes = {
  title: PropTypes.string,
};
GlassesIcon.defaultProps = {
  title: 'Glasses',
};

// eslint-disable-next-line react/require-default-props
export const GraduationCapIcon = ({ title, ...props }) => (
  <Icon src={graduationCapIconPath} title={title} {...props} />
);
GraduationCapIcon.propTypes = {
  title: PropTypes.string,
};
GraduationCapIcon.defaultProps = {
  title: 'Graduation Cap',
};

// eslint-disable-next-line react/require-default-props
export const HeartIcon = ({ title, ...props }) => (
  <Icon src={heartIconPath} title={title} {...props} />
);
HeartIcon.propTypes = {
  title: PropTypes.string,
};
HeartIcon.defaultProps = {
  title: 'Heart',
};

// eslint-disable-next-line react/require-default-props
export const HideIcon = ({ title, ...props }) => (
  <Icon src={hideIconPath} title={title} {...props} />
);
HideIcon.propTypes = {
  title: PropTypes.string,
};
HideIcon.defaultProps = {
  title: 'Hide',
};

// eslint-disable-next-line react/require-default-props
export const HomeIcon = ({ title, ...props }) => (
  <Icon src={homeIconPath} title={title} {...props} />
);
HomeIcon.propTypes = {
  title: PropTypes.string,
};
HomeIcon.defaultProps = {
  title: 'Home',
};

// eslint-disable-next-line react/require-default-props
export const HomeSimpleIcon = ({ title, ...props }) => (
  <Icon src={homeSimpleIconPath} title={title} {...props} />
);
HomeSimpleIcon.propTypes = {
  title: PropTypes.string,
};
HomeSimpleIcon.defaultProps = {
  title: 'Home Simple',
};

// eslint-disable-next-line react/require-default-props
export const HospitalIcon = ({ title, ...props }) => (
  <Icon src={hospitalPath} title={title} {...props} />
);
HospitalIcon.propTypes = {
  title: PropTypes.string,
};
HospitalIcon.defaultProps = {
  title: 'Hospital',
};

// eslint-disable-next-line react/require-default-props
export const HospitalBedIcon = ({ title, ...props }) => (
  <Icon src={hospitalBedPath} title={title} {...props} />
);
HospitalBedIcon.propTypes = {
  title: PropTypes.string,
};
HospitalBedIcon.defaultProps = {
  title: 'Hospital Bed',
};

// eslint-disable-next-line react/require-default-props
export const HospitalHouseIcon = ({ title, ...props }) => (
  <Icon src={hospitalHousePath} title={title} {...props} />
);
HospitalHouseIcon.propTypes = {
  title: PropTypes.string,
};
HospitalHouseIcon.defaultProps = {
  title: 'Hospital House',
};

// eslint-disable-next-line react/require-default-props
export const HospitalPhoneIcon = ({ title, ...props }) => (
  <Icon src={hospitalPhonePath} title={title} {...props} />
);
HospitalPhoneIcon.propTypes = {
  title: PropTypes.string,
};
HospitalPhoneIcon.defaultProps = {
  title: 'Hospital Phone',
};

// eslint-disable-next-line react/require-default-props
export const InfoIcon = ({ title, ...props }) => (
  <Icon src={infoIconPath} title={title} {...props} />
);
InfoIcon.propTypes = {
  title: PropTypes.string,
};
InfoIcon.defaultProps = {
  title: 'Information',
};

// eslint-disable-next-line react/require-default-props
export const InterviewIcon = ({ title, ...props }) => (
  <Icon src={interviewIconPath} title={title} {...props} />
);
InterviewIcon.propTypes = {
  title: PropTypes.string,
};
InterviewIcon.defaultProps = {
  title: 'Interview',
};

// eslint-disable-next-line react/require-default-props
export const LightningIcon = ({ title, ...props }) => (
  <Icon src={lightningIconPath} title={title} {...props} />
);
LightningIcon.propTypes = {
  title: PropTypes.string,
};
LightningIcon.defaultProps = {
  title: 'Lightning',
};

// eslint-disable-next-line react/require-default-props
export const LocationPinIcon = ({ title, ...props }) => (
  <Icon src={locationPinIconPath} title={title} {...props} />
);
LocationPinIcon.propTypes = {
  title: PropTypes.string,
};
LocationPinIcon.defaultProps = {
  title: 'Location',
};

// eslint-disable-next-line react/require-default-props
export const LocationPinThinIcon = ({ title, ...props }) => (
  <Icon src={locationPinThinIconPath} title={title} {...props} />
);
LocationPinThinIcon.propTypes = {
  title: PropTypes.string,
};
LocationPinThinIcon.defaultProps = {
  title: 'Location',
};

// eslint-disable-next-line react/require-default-props
export const LockIcon = ({ title, ...props }) => (
  <Icon src={lockIconPath} title={title} {...props} />
);
LockIcon.propTypes = {
  title: PropTypes.string,
};
LockIcon.defaultProps = {
  title: 'Lock',
};

// eslint-disable-next-line react/require-default-props
export const Logo = ({ title, ...props }) => (
  <Icon src={logoPath} title={title} {...props} />
);
Logo.propTypes = {
  title: PropTypes.string,
};
Logo.defaultProps = {
  title: 'Incredible Health',
};

// eslint-disable-next-line react/require-default-props
export const LogoIcon = ({ title, ...props }) => (
  <Icon src={logoIconPath} title={title} {...props} />
);
LogoIcon.propTypes = {
  title: PropTypes.string,
};
LogoIcon.defaultProps = {
  title: 'Incredible Health',
};

// eslint-disable-next-line react/require-default-props
export const MaximizeIcon = ({ title, ...props }) => (
  <Icon src={maximizePath} title={title} {...props} />
);
MaximizeIcon.propTypes = {
  title: PropTypes.string,
};
MaximizeIcon.defaultProps = {
  title: 'Maximize',
};

// eslint-disable-next-line react/require-default-props
export const MessageBubbleIcon = ({ title, ...props }) => (
  <Icon src={messageBubblePath} title={title} {...props} />
);
MessageBubbleIcon.propTypes = {
  title: PropTypes.string,
};
MessageBubbleIcon.defaultProps = {
  title: 'Message Bubble',
};

// eslint-disable-next-line react/require-default-props
export const MinimizeIcon = ({ title, ...props }) => (
  <Icon src={minimizePath} title={title} {...props} />
);
MinimizeIcon.propTypes = {
  title: PropTypes.string,
};
MinimizeIcon.defaultProps = {
  title: 'Minimize',
};

// eslint-disable-next-line react/require-default-props
export const MinusIcon = ({ title, ...props }) => (
  <Icon src={minusPath} title={title} {...props} />
);
MinusIcon.propTypes = {
  title: PropTypes.string,
};
MinusIcon.defaultProps = {
  title: 'Minus',
};

// eslint-disable-next-line react/require-default-props
export const MoneyBagIcon = ({ title, ...props }) => (
  <Icon src={moneyBagPath} title={title} {...props} />
);
MoneyBagIcon.propTypes = {
  title: PropTypes.string,
};
MoneyBagIcon.defaultProps = {
  title: 'Money Bag',
};

// eslint-disable-next-line react/require-default-props
export const MoneyBagsIcon = ({ title, ...props }) => (
  <Icon src={moneyBagsPath} title={title} {...props} />
);
MoneyBagsIcon.propTypes = {
  title: PropTypes.string,
};
MoneyBagsIcon.defaultProps = {
  title: 'Money Bags',
};

// eslint-disable-next-line react/require-default-props
export const MoneyBillIcon = ({ title, ...props }) => (
  <Icon src={moneyBillPath} title={title} {...props} />
);
MoneyBillIcon.propTypes = {
  title: PropTypes.string,
};
MoneyBillIcon.defaultProps = {
  title: 'Money Bill',
};

// eslint-disable-next-line react/require-default-props
export const MoonIcon = ({ title, ...props }) => (
  <Icon src={moonPath} title={title} {...props} />
);
MoonIcon.propTypes = {
  title: PropTypes.string,
};
MoonIcon.defaultProps = {
  title: 'Moon',
};

// eslint-disable-next-line react/require-default-props
export const MoveDownIcon = ({ title, ...props }) => (
  <Icon src={moveDownPath} title={title} {...props} />
);
MoveDownIcon.propTypes = {
  title: PropTypes.string,
};
MoveDownIcon.defaultProps = {
  title: 'Move Down',
};

// eslint-disable-next-line react/require-default-props
export const NextIcon = ({ title, ...props }) => (
  <Icon src={nextPath} title={title} {...props} />
);
NextIcon.propTypes = {
  title: PropTypes.string,
};
NextIcon.defaultProps = {
  title: 'Next',
};

// eslint-disable-next-line react/require-default-props
export const NewWindow = ({ title, ...props }) => (
  <Icon src={newWindowPath} title={title} {...props} />
);
NewWindow.propTypes = {
  title: PropTypes.string,
};
NewWindow.defaultProps = {
  title: 'New Window',
};

// eslint-disable-next-line react/require-default-props
export const NurseIcon = ({ title, ...props }) => (
  <Icon src={nursePath} title={title} {...props} />
);
NurseIcon.propTypes = {
  title: PropTypes.string,
};
NurseIcon.defaultProps = {
  title: 'Nurse',
};

// eslint-disable-next-line react/require-default-props
export const OpenEnvelopeIcon = ({ title, ...props }) => (
  <Icon src={openEnvelopePath} title={title} {...props} />
);
OpenEnvelopeIcon.propTypes = {
  title: PropTypes.string,
};
OpenEnvelopeIcon.defaultProps = {
  title: 'Open Envelope',
};

// eslint-disable-next-line react/require-default-props
export const PaperAirplaneIcon = ({ title, ...props }) => (
  <Icon src={paperAirplanePath} title={title} {...props} />
);
PaperAirplaneIcon.propTypes = {
  title: PropTypes.string,
};
PaperAirplaneIcon.defaultProps = {
  title: 'Paper Airplane',
};

// eslint-disable-next-line react/require-default-props
export const PaperclipIcon = ({ title, ...props }) => (
  <Icon src={paperclipPath} title={title} {...props} />
);
PaperclipIcon.propTypes = {
  title: PropTypes.string,
};
PaperclipIcon.defaultProps = {
  title: 'Paperclip',
};

// eslint-disable-next-line react/require-default-props
export const PerformanceUserGraphIcon = ({ title, ...props }) => (
  <Icon src={performanceUserGraph} title={title} {...props} />
);
PerformanceUserGraphIcon.propTypes = {
  title: PropTypes.string,
};
PerformanceUserGraphIcon.defaultProps = {
  title: 'Performance User Graph',
};

// eslint-disable-next-line react/require-default-props
export const PencilIcon = ({ title, ...props }) => (
  <Icon src={pencilPath} title={title} {...props} />
);
PencilIcon.propTypes = {
  title: PropTypes.string,
};
PencilIcon.defaultProps = {
  title: 'Pencil',
};

// eslint-disable-next-line react/require-default-props
export const PhoneAutoDialIcon = ({ title, ...props }) => (
  <Icon src={phoneAutoDialPath} title={title} {...props} />
);
PhoneAutoDialIcon.propTypes = {
  title: PropTypes.string,
};
PhoneAutoDialIcon.defaultProps = {
  title: 'Phone Auto-Dial',
};

// eslint-disable-next-line react/require-default-props
export const PiggyBankIcon = ({ title, ...props }) => (
  <Icon src={piggyBankIconPath} title={title} {...props} />
);
PiggyBankIcon.propTypes = {
  title: PropTypes.string,
};
PiggyBankIcon.defaultProps = {
  title: 'Piggy Bank',
};

// eslint-disable-next-line react/require-default-props
export const PhoneCallIcon = ({ title, ...props }) => (
  <Icon src={callIconPath} title={title} {...props} />
);
PhoneCallIcon.propTypes = {
  title: PropTypes.string,
};
PhoneCallIcon.defaultProps = {
  title: 'Phone Call',
};

// eslint-disable-next-line react/require-default-props
export const PinIcon = ({ title, ...props }) => (
  <Icon src={pinPath} title={title} {...props} />
);
PinIcon.propTypes = {
  title: PropTypes.string,
};
PinIcon.defaultProps = {
  title: 'Pin',
};

// eslint-disable-next-line react/require-default-props
export const PinOutlineIcon = ({ title, ...props }) => (
  <Icon src={pinOutlinePath} title={title} {...props} />
);
PinOutlineIcon.propTypes = {
  title: PropTypes.string,
};
PinOutlineIcon.defaultProps = {
  title: 'Pin',
};

// eslint-disable-next-line react/require-default-props
export const PlusIcon = ({ title, ...props }) => (
  <Icon src={plusPath} title={title} {...props} />
);
PlusIcon.propTypes = {
  title: PropTypes.string,
};
PlusIcon.defaultProps = {
  title: 'Plus',
};

// eslint-disable-next-line react/require-default-props
export const PreviousIcon = ({ title, ...props }) => (
  <Icon src={previousPath} title={title} {...props} />
);
PreviousIcon.propTypes = {
  title: PropTypes.string,
};
PreviousIcon.defaultProps = {
  title: 'Previous',
};

// eslint-disable-next-line react/require-default-props
export const ProfileIcon = ({ title, ...props }) => (
  <Icon src={profileIconPath} title={title} {...props} />
);
ProfileIcon.propTypes = {
  title: PropTypes.string,
};
ProfileIcon.defaultProps = {
  title: 'Profile',
};

// eslint-disable-next-line react/require-default-props
export const ProgressEmptyIcon = ({ title, ...props }) => (
  <Icon src={progressEmptyPath} title={title} {...props} />
);
ProgressEmptyIcon.propTypes = {
  title: PropTypes.string,
};
ProgressEmptyIcon.defaultProps = {
  title: 'Progress step zero',
};

// eslint-disable-next-line react/require-default-props
export const ProgressOneIcon = ({ title, ...props }) => (
  <Icon src={progressOnePath} title={title} {...props} />
);
ProgressOneIcon.propTypes = {
  title: PropTypes.string,
};
ProgressOneIcon.defaultProps = {
  title: 'Progress first step',
};

// eslint-disable-next-line react/require-default-props
export const ProgressTwoIcon = ({ title, ...props }) => (
  <Icon src={progressTwoPath} title={title} {...props} />
);
ProgressTwoIcon.propTypes = {
  title: PropTypes.string,
};
ProgressTwoIcon.defaultProps = {
  title: 'Progress second step',
};

// eslint-disable-next-line react/require-default-props
export const ProgressThreeIcon = ({ title, ...props }) => (
  <Icon src={progressThreePath} title={title} {...props} />
);
ProgressThreeIcon.propTypes = {
  title: PropTypes.string,
};
ProgressThreeIcon.defaultProps = {
  title: 'Progress third step',
};

// eslint-disable-next-line react/require-default-props
export const ProgressFourIcon = ({ title, ...props }) => (
  <Icon src={progressFourPath} title={title} {...props} />
);
ProgressFourIcon.propTypes = {
  title: PropTypes.string,
};
ProgressFourIcon.defaultProps = {
  title: 'Progress last step',
};

// eslint-disable-next-line react/require-default-props
export const ReturnIcon = ({ title, ...props }) => (
  <Icon src={returnPath} title={title} {...props} />
);
ReturnIcon.propTypes = {
  title: PropTypes.string,
};
ReturnIcon.defaultProps = {
  title: 'Return',
};

// eslint-disable-next-line react/require-default-props
export const RefreshIcon = ({ title, ...props }) => (
  <Icon src={refreshPath} title={title} {...props} />
);
RefreshIcon.propTypes = {
  title: PropTypes.string,
};
RefreshIcon.defaultProps = {
  title: 'Refresh',
};

// eslint-disable-next-line react/require-default-props
export const ReplyIcon = ({ title, ...props }) => (
  <Icon src={replyPath} title={title} {...props} />
);
ReplyIcon.propTypes = {
  title: PropTypes.string,
};
ReplyIcon.defaultProps = {
  title: 'Reply',
};

// eslint-disable-next-line react/require-default-props
export const ColorSwatchGrapeIcon = ({ title, ...props }) => (
  <Icon src={colorSwatchGrapePath} title={title} {...props} />
);
ColorSwatchGrapeIcon.propTypes = {
  title: PropTypes.string,
};
ColorSwatchGrapeIcon.defaultProps = {
  title: 'Grape',
};

// eslint-disable-next-line react/require-default-props
export const ColorSwatchKaleIcon = ({ title, ...props }) => (
  <Icon src={colorSwatchKalePath} title={title} {...props} />
);
ColorSwatchKaleIcon.propTypes = {
  title: PropTypes.string,
};
ColorSwatchKaleIcon.defaultProps = {
  title: 'Grape',
};

// eslint-disable-next-line react/require-default-props
export const ColorSwatchOceanIcon = ({ title, ...props }) => (
  <Icon src={colorSwatchOceanPath} title={title} {...props} />
);
ColorSwatchOceanIcon.propTypes = {
  title: PropTypes.string,
};
ColorSwatchOceanIcon.defaultProps = {
  title: 'Grape',
};

// eslint-disable-next-line react/require-default-props
export const ColorSwatchRustIcon = ({ title, ...props }) => (
  <Icon src={colorSwatchRustPath} title={title} {...props} />
);
ColorSwatchRustIcon.propTypes = {
  title: PropTypes.string,
};
ColorSwatchRustIcon.defaultProps = {
  title: 'Rust',
};

// eslint-disable-next-line react/require-default-props
export const ColorSwatchSlateIcon = ({ title, ...props }) => (
  <Icon src={colorSwatchSlatePath} title={title} {...props} />
);
ColorSwatchSlateIcon.propTypes = {
  title: PropTypes.string,
};
ColorSwatchSlateIcon.defaultProps = {
  title: 'Rust',
};

// eslint-disable-next-line react/require-default-props
export const RightIcon = ({ title, ...props }) => (
  <Icon src={rightPath} title={title} {...props} />
);
RightIcon.propTypes = {
  title: PropTypes.string,
};
RightIcon.defaultProps = {
  title: 'Right',
};

// eslint-disable-next-line react/require-default-props
export const RocketIcon = ({ title, ...props }) => (
  <Icon src={rocketPath} title={title} {...props} />
);
RocketIcon.propTypes = {
  title: PropTypes.string,
};
RocketIcon.defaultProps = {
  title: 'Rocket',
};

// eslint-disable-next-line react/require-default-props
export const ScrollIcon = ({ title, ...props }) => (
  <Icon src={scrollIconPath} title={title} {...props} />
);
ScrollIcon.propTypes = {
  title: PropTypes.string,
};
ScrollIcon.defaultProps = {
  title: 'Scroll',
};

// eslint-disable-next-line react/require-default-props
export const SearchIcon = ({ title, ...props }) => (
  <Icon src={searchPath} title={title} {...props} />
);
SearchIcon.propTypes = {
  title: PropTypes.string,
};
SearchIcon.defaultProps = {
  title: 'Search',
};

// eslint-disable-next-line react/require-default-props
export const SendDocumentIcon = ({ title, ...props }) => (
  <Icon src={sendDocumentPath} title={title} {...props} />
);
SendDocumentIcon.propTypes = {
  title: PropTypes.string,
};
SendDocumentIcon.defaultProps = {
  title: 'Send Document',
};

// eslint-disable-next-line react/require-default-props
export const SettingsIcon = ({ title, ...props }) => (
  <Icon src={settingsPath} title={title} {...props} />
);
SettingsIcon.propTypes = {
  title: PropTypes.string,
};
SettingsIcon.defaultProps = {
  title: 'Settings',
};

// eslint-disable-next-line react/require-default-props
export const ShieldCheckIcon = ({ title, ...props }) => (
  <Icon src={shieldCheckIconPath} title={title} {...props} />
);
ShieldCheckIcon.propTypes = {
  title: PropTypes.string,
};
ShieldCheckIcon.defaultProps = {
  title: 'Shield Check',
};

// eslint-disable-next-line react/require-default-props
export const SpinnerDarkIcon = ({ title, ...props }) => (
  <Icon src={spinnerDarkPath} title={title} {...props} />
);
SpinnerDarkIcon.propTypes = {
  title: PropTypes.string,
};
SpinnerDarkIcon.defaultProps = {
  title: 'Spinner Dark',
};

// eslint-disable-next-line react/require-default-props
export const SproutIcon = ({ title, ...props }) => (
  <Icon src={sproutIconPath} title={title} {...props} />
);
SproutIcon.propTypes = {
  title: PropTypes.string,
};
SproutIcon.defaultProps = {
  title: 'Sprout',
};

// eslint-disable-next-line react/require-default-props
export const SquareXIcon = ({ title, ...props }) => (
  <Icon src={squareXIconPath} title={title} {...props} />
);
SquareXIcon.propTypes = {
  title: PropTypes.string,
};
SquareXIcon.defaultProps = {
  title: 'Square X',
};

// eslint-disable-next-line react/require-default-props
export const StarIcon = ({ title, ...props }) => (
  <Icon src={starIconPath} title={title} {...props} />
);
StarIcon.propTypes = {
  title: PropTypes.string,
};
StarIcon.defaultProps = {
  title: 'Star',
};

// eslint-disable-next-line react/require-default-props
export const StairsAscendIcon = ({ title, ...props }) => (
  <Icon src={stairsAscendPath} title={title} {...props} />
);
StairsAscendIcon.propTypes = {
  title: PropTypes.string,
};
StairsAscendIcon.defaultProps = {
  title: 'Stairs Ascend',
};

// eslint-disable-next-line react/require-default-props
export const SunIcon = ({ title, ...props }) => (
  <Icon src={sunPath} title={title} {...props} />
);
SunIcon.propTypes = {
  title: PropTypes.string,
};
SunIcon.defaultProps = {
  title: 'Sun',
};

// eslint-disable-next-line react/require-default-props
export const SunMoonIcon = ({ title, ...props }) => (
  <Icon src={sunMoonPath} title={title} {...props} />
);
SunMoonIcon.propTypes = {
  title: PropTypes.string,
};
SunMoonIcon.defaultProps = {
  title: 'Sun Moon',
};

// eslint-disable-next-line react/require-default-props
export const TicketIcon = ({ title, ...props }) => (
  <Icon src={ticketPath} title={title} {...props} />
);
TicketIcon.propTypes = {
  title: PropTypes.string,
};
TicketIcon.defaultProps = {
  title: 'Ticket',
};

// eslint-disable-next-line react/require-default-props
export const TimeSensitiveMailIcon = ({ title, ...props }) => (
  <Icon src={timeSensitiveMailPath} title={title} {...props} />
);
TimeSensitiveMailIcon.propTypes = {
  title: PropTypes.string,
};
TimeSensitiveMailIcon.defaultProps = {
  title: 'Mail with clock',
};

// eslint-disable-next-line react/require-default-props
export const TimeSensitiveMailRejectIcon = ({ title, ...props }) => (
  <Icon src={timeSensitiveMailRejectPath} title={title} {...props} />
);
TimeSensitiveMailRejectIcon.propTypes = {
  title: PropTypes.string,
};
TimeSensitiveMailRejectIcon.defaultProps = {
  title: 'Mail with stop sign',
};

// eslint-disable-next-line react/require-default-props
export const TrashIcon = ({ title, ...props }) => (
  <Icon src={trashPath} title={title} {...props} />
);
TrashIcon.propTypes = {
  title: PropTypes.string,
};
TrashIcon.defaultProps = {
  title: 'Trash',
};

// eslint-disable-next-line react/require-default-props
export const TruckIcon = ({ title, ...props }) => (
  <Icon src={truckPath} title={title} {...props} />
);
TruckIcon.propTypes = {
  title: PropTypes.string,
};
TruckIcon.defaultProps = {
  title: 'Truck',
};

// eslint-disable-next-line react/require-default-props
export const UnfollowIcon = ({ title, ...props }) => (
  <Icon src={unfollowIconPath} title={title} {...props} />
);
UnfollowIcon.propTypes = {
  title: PropTypes.string,
};
UnfollowIcon.defaultProps = {
  title: 'Unfollow',
};

// eslint-disable-next-line react/require-default-props
export const UpIcon = ({ title, ...props }) => (
  <Icon src={upPath} title={title} {...props} />
);
UpIcon.propTypes = {
  title: PropTypes.string,
};
UpIcon.defaultProps = {
  title: 'Up',
};

// eslint-disable-next-line react/require-default-props
export const UploadIcon = ({ title, ...props }) => (
  <Icon src={uploadPath} title={title} {...props} />
);
UploadIcon.propTypes = {
  title: PropTypes.string,
};
UploadIcon.defaultProps = {
  title: 'Upload',
};

// eslint-disable-next-line react/require-default-props
export const UsersIcon = ({ title, ...props }) => (
  <Icon src={usersPath} title={title} {...props} />
);
UsersIcon.propTypes = {
  title: PropTypes.string,
};
UsersIcon.defaultProps = {
  title: 'Users',
};

// eslint-disable-next-line react/require-default-props
export const ViewIcon = ({ title, ...props }) => (
  <Icon src={viewIconPath} title={title} {...props} />
);
ViewIcon.propTypes = {
  title: PropTypes.string,
};
ViewIcon.defaultProps = {
  title: 'View',
};

// eslint-disable-next-line react/require-default-props
export const Wallet = ({ title, ...props }) => (
  <Icon src={walletPath} title={title} {...props} />
);
Wallet.propTypes = {
  title: PropTypes.string,
};
Wallet.defaultProps = {
  title: 'Wallet',
};

// eslint-disable-next-line react/require-default-props
export const WandIcon = ({ title, ...props }) => (
  <Icon src={wandPath} title={title} {...props} />
);
WandIcon.propTypes = {
  title: PropTypes.string,
};
WandIcon.defaultProps = {
  title: 'Wand',
};

// eslint-disable-next-line react/require-default-props
export const XIcon = ({ title, ...props }) => (
  <Icon src={xIconPath} title={title} {...props} />
);
XIcon.propTypes = {
  title: PropTypes.string,
};
XIcon.defaultProps = {
  title: 'X Circle',
};

// eslint-disable-next-line react/require-default-props
export const XCircleIcon = ({ title, ...props }) => (
  <Icon src={xCircleIconPath} title={title} {...props} />
);
XCircleIcon.propTypes = {
  title: PropTypes.string,
};
XCircleIcon.defaultProps = {
  title: 'X Circle',
};
