import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useCalendlyEventListener, PopupModal } from 'react-calendly';

import { scheduleScreening } from 'shared/actions/screeningActions';
import { Button } from 'shared/components/ihcl/button';
import { getCalendlyProps } from 'shared/helpers/calendly';
import { trackClick, trackEvent, MixpanelEvent } from 'shared/helpers/tracking';

export function ScheduleScreeningButton({
  buttonSize = 'compact',
  callToAction = 'Pick an intro call time',
  prePopup = null,
  onScreeningScheduled = null,
  requestInProgress = false,
  scheduleScreeningAction,
  talentProfile,
  clickEventName = 'talent.screening.schedule_call',
  eventProperties = null,
  ...buttonProps
}) {
  const history = useHistory();
  const containerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangingLocation, setisChangingLocation] = useState(false);

  useEffect(() => {
    trackEvent(MixpanelEvent.screeningPromptViewed, eventProperties);
  }, []);

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (isModalOpen) {
        scheduleScreeningAction(talentProfile.id);
        trackEvent(MixpanelEvent.screeningCallScheduled, eventProperties);

        if (onScreeningScheduled) {
          onScreeningScheduled();
        }
      }
    },
  });

  const updateHash = (newHash) => {
    history.push(`${history.location.pathname}${newHash}`);
  };

  const handleClick = () => {
    if (prePopup) {
      prePopup();
    }

    trackClick(clickEventName, {
      ...eventProperties,
      screening_type: talentProfile.screening_type,
    });

    // Workaround to force talent from onboarding to talent app. This is because
    // 1. IntroCallModal does NOT work in talent app. (Screneer calls evolution)
    // 2. Onboarding usually does not have featureFlags set, so cannot determine which to show.
    if (
      talentProfile.screening_type === 'AppointmentWindowScreening' &&
      window.location.href.indexOf('/talent_onboard/') > -1
    ) {
      setisChangingLocation(true);
      window.location = '/talent/intro_call';
    } else if (talentProfile.screening_type === 'AppointmentWindowScreening') {
      updateHash('#introCall');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div ref={containerRef} id="schedule-screening-button-wrapper">
      <Button
        color="alternate"
        size={buttonSize}
        onClick={handleClick}
        disabled={requestInProgress}
        isLoading={requestInProgress || isChangingLocation}
        {...buttonProps}
      >
        {callToAction}
      </Button>
      <PopupModal
        {...getCalendlyProps(talentProfile)}
        open={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        rootElement={containerRef.current}
      />
    </div>
  );
}

ScheduleScreeningButton.propTypes = {
  buttonSize: PropTypes.string,
  callToAction: PropTypes.node,
  clickEventName: PropTypes.string,
  eventProperties: PropTypes.object,
  onScreeningScheduled: PropTypes.func,
  prePopup: PropTypes.func,
  requestInProgress: PropTypes.bool,
  scheduleScreeningAction: PropTypes.func.isRequired,
  scheduleVariant: PropTypes.string,
  talentProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  requestInProgress: state.uiState.get('scheduleScreeningRequestInProgress'),
  talentProfile: ownProps.talentProfile || state.talent_profile,
});

export default connect(mapStateToProps, {
  scheduleScreeningAction: scheduleScreening,
})(ScheduleScreeningButton);
