import { doPatch, doPost } from 'shared/helpers/http';
import { TalentProfile } from 'talent/types';

export const generateResume = ({
  id,
  queryParams = '',
}: {
  id: TalentProfile['id'];
  queryParams?: string;
}): Promise<TalentProfile> =>
  doPost(`/talent_profile/${id}/generate_resume${queryParams}`);

export const updateTalentProfile = ({
  talentProfileId,
  body,
}: {
  talentProfileId: TalentProfile['id'];
  body: Partial<TalentProfile>;
}): Promise<TalentProfile> =>
  doPatch(`/talent_profile/${talentProfileId}`, body);
