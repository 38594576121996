export const isNative = (minVersion) => {
  try {
    let hasRequiredVersion = false;
    if (
      !minVersion ||
      (window.localStorage &&
        window.localStorage.getItem('nativeAppVersion') >= minVersion)
    ) {
      hasRequiredVersion = true;
    }

    return (
      hasRequiredVersion &&
      (window.ReactNativeWebView != null ||
        (window.localStorage &&
          window.localStorage.getItem('isNative') === 'true'))
    );
  } catch (err) {
    if (err && err.name !== 'SecurityError') {
      window.Sentry.captureException(err);
    }

    return false;
  }
};
