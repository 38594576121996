import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { isFunction } from 'lodash';

import {
  registerImpressionId,
  trackTalentPageView,
  getPageName,
} from 'shared/helpers/tracking';

const withTracking = (WrappedComponent) => {
  const trackPage = (page) => {
    if (window.IH && window.IH.googleAnalyticsKey) {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = class extends React.Component {
    componentDidMount() {
      const pathName = this.props.location.pathname;
      trackPage(pathName);

      registerImpressionId();

      const pageName = getPageName(pathName);
      let properties = {};
      if (isFunction(WrappedComponent.getPageViewProperties)) {
        properties = WrappedComponent.getPageViewProperties(this.props);
      }
      trackTalentPageView(pageName, properties);
    }

    componentDidUpdate(prevProps) {
      const prevPage = prevProps.location.pathname;
      const currentPage = this.props.location.pathname;

      if (currentPage !== prevPage) {
        trackPage(currentPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = {
    // eslint-disable-next-line react/require-default-props
    location: PropTypes.object,
  };

  return HOC;
};

export default withTracking;
