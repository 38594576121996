import React from 'react';

import { ButtonSelect } from 'shared/components/ihcl/button_select';

type ButtonSelectorProps = {
  options: string[];
  values: string[];
  updateValues: Function;
};

const ButtonSelector: React.FC<ButtonSelectorProps> = ({
  options,
  values,
  updateValues,
}) => {
  const optionsMap = options.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <ButtonSelect
      multiSelect
      orientation="horizontal"
      options={optionsMap}
      onChange={(value, option, remove) => {
        let newValues = values ? [...values] : [];
        if (remove) {
          newValues = newValues.filter((specialties) => specialties !== value);
        } else {
          newValues.push(value);
        }
        updateValues(newValues);
      }}
      selectedOptions={optionsMap.filter(
        (option) => values && values.includes(option.value)
      )}
      padding="minimal"
    />
  );
};

export default ButtonSelector;
