import { MutationFunction, useMutation } from '@tanstack/react-query';

export function baseMutation<T>(mutationFn: MutationFunction) {
  return ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: T) => void;
    onError?: (err: any) => void;
  }) =>
    useMutation({
      mutationFn,
      onSuccess,
      onError,
    });
}
