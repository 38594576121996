import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { styled, withStyle } from 'shared/components/ihcl/styled';
import { useForm } from 'react-hook-form';
import Cookie from 'js-cookie';
import throttle from 'just-throttle';

import { pathToVariant } from 'shared/helpers/activateABTest';
import getFetchSignupSettings from 'shared/helpers/getFetchSignupSettings';
import sharedConstants from 'shared/constants/constants.json';
import { Button } from 'shared/components/ihcl/button';
import { StatefulInput } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { trackHiringSignUp } from 'shared/helpers/tracking';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';

import cedarsLogo from 'images/employer-logos/cedars_sinai_square_logo.png';
import northwellLogo from 'images/employer-logos/northwell_health_square_logo.png';
import yaleLogo from 'images/employer-logos/yale_new_haven_health_square_logo.png';

import gradientBlobsPath from 'images/illustrations/gradient_blobs.svg';
import gradientBlobsWidePath from 'images/illustrations/gradient_blobs_wide.svg';

import JobCard from './JobCard';

import estimatedJobMatchCount from '../../helpers/estimatedJobMatchCount';

import {
  convertStateToAttributes,
  getAugmentedOnboardingData,
} from '../../helpers/talentProfileConversions';

import validateTalentProfile, {
  validateTalentProfileField,
} from '../../helpers/validateTalentProfile';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle as OnboardingTitleBase,
} from '../../containers/Onboarding';
import { setSentryUser } from '../../../shared/helpers/tracking';
import OnboardingTestimonial from './OnboardingTestimonial';

const title = 'Where can we send your job matches?';

const FooterText = styled('div', ({ $theme }) => ({
  color: $theme.colors.gray,
  fontSize: $theme.sizing.scale550,
  marginTop: '16px',
  marginRight: '16px',
  marginLeft: '16px',
  textAlign: 'center',
}));

const OnboardingTitle = withStyle(
  OnboardingTitleBase,
  ({ $additionalTopSpacing = false }) => ({
    marginTop: $additionalTopSpacing ? '40px' : '32px',
    marginBottom: '20px',
    marginRight: '16px',
    marginLeft: '16px',
  })
);

const CardsContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '70vw',
  overflow: 'hidden',
  maxHeight: '37vh',
  marginTop: '32px',
});

const CardsContainerBackground = styled(
  'div',
  ({ $showWideBackground = false }) => ({
    position: 'absolute',
    width: '100%',
    height: '70vw',
    maxHeight: '37vh',
    backgroundImage: `url(${
      $showWideBackground ? gradientBlobsWidePath : gradientBlobsPath
    })`,
    backgroundSize: $showWideBackground ? 'auto 100%' : 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: $showWideBackground ? 'top center' : 'center',
    animationDuration: '2s',
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 100,
      },
    },
  })
);

const SidePaddingWrapper = styled('div', {
  paddingRight: '16px',
  paddingLeft: '16px',
});

// eslint-disable-next-line react/require-default-props
const OnboardingEmail = ({ nextAction, stepNumber, urlQuery = {} }) => {
  const {
    history,
    onboardingBasePath,
    onboardingData,
    onboardingOptions,
    onboardingUpdates,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const formRef = useRef(null);
  const initialValues = {
    email: '',
  };
  const {
    clearErrors,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
  });
  const formErrors = {
    ...errors,
    ...errors.user,
  };
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const variant = pathToVariant(onboardingBasePath);
  const isYukiVariant = variant === 'yuki';

  const jobMatchCount = estimatedJobMatchCount(
    onboardingData,
    onboardingOptions
  );

  let matchCountExperiment = null;
  if (jobMatchCount) {
    if (variant === 'jarvis') {
      matchCountExperiment = 'JobMatchOnboardingExperimentTreatment';
    } else {
      matchCountExperiment = 'JobMatchOnboardingExperimentControl';
    }
  }

  useEffect(() => {
    mixpanel.track('Onboard Email Page Viewed', {
      Experiment: matchCountExperiment,
      'Match Count': jobMatchCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (formData) => {
    const talentProfile = {
      ...formData,
    };
    delete talentProfile.fullName;
    const validationErrors = validateTalentProfile(
      talentProfile,
      Object.keys(talentProfile)
    );
    if (validationErrors.size === 0) {
      const signUpUrl = '/users/sign_up/talent.json';
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let profileAttributes = {
        onboard_variant: variant,
        signup_intent: 'hiring',
        timezone_from_onboarding: browserTimezone,
      };

      if (matchCountExperiment) {
        profileAttributes.talent_tags = [matchCountExperiment];
      }
      if (urlQuery.source) {
        profileAttributes.source = urlQuery.source;
      }
      sharedConstants.TALENT_PROFILE_TRACKING_PARAM_NAMES.forEach(
        (utmParamName) => {
          if (urlQuery[utmParamName]) {
            profileAttributes[utmParamName] = urlQuery[utmParamName];
          }
        }
      );
      if (urlQuery.ref) {
        profileAttributes.referrer_code = urlQuery.ref;
      }
      const gclid = Cookie.get('gclid');
      if (gclid) {
        profileAttributes.google_click_id = gclid;
      }
      let localOnboardingUpdates = {};
      if (typeof localforage !== 'undefined') {
        localOnboardingUpdates = await localforage.getItem('onboardingUpdates');
      } else {
        localOnboardingUpdates = convertStateToAttributes(
          onboardingUpdates,
          Object.keys(onboardingUpdates),
          talentProfile
        );
      }
      profileAttributes = {
        ...profileAttributes,
        ...localOnboardingUpdates,
        ...onboardingUpdates,
        current_onboard_step: stepNumber + 1,
      };
      const body = {
        user: {
          ...talentProfile,
          talent_profile_attributes: profileAttributes,
          remember_me: true,
        },
      };

      if (!talentProfile || !talentProfile.password) {
        body.gen_password = true;
      }
      fetch(signUpUrl, getFetchSignupSettings(body))
        .then((data) => data.json())
        .then(async (json) => {
          setIsFormSubmitting(false);
          if (json && json.errors) {
            Object.keys(json.errors).forEach((key) => {
              setError(key, {
                type: 'validationError',
                message: json.errors[key].join('; '),
              });
            });
          } else {
            if (typeof localforage !== 'undefined') {
              try {
                await localforage.removeItem('onboardingUpdates');
              } catch (err) {
                console.error('Error removing onboardingUpdates:', err);
              }
            }
            setOnboardingData(
              getAugmentedOnboardingData(
                onboardingData,
                json,
                onboardingOptions
              )
            );
            trackHiringSignUp(json);
            if (json.user_id && json.user_id.toString()) {
              mixpanel.alias(json.user_id.toString());
            } else {
              console.error(`cannot alias user_id: ${json.user_id}`);
            }
            mixpanel.register({ 'User Type': 'talent' });
            mixpanel.track(`Onboard Step ${stepNumber} Completed`);
            setSentryUser({
              id: json.id,
              email: json.email,
              username: json.full_name,
            });
            history.push(nextAction);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsFormSubmitting(false);
          const errorMsg =
            'Oops, there was a problem connecting ' +
            'to the server. Please check your internet connection ' +
            'and try again, or refresh the page.';
          toaster.negative(errorMsg);
        });
    }
  };
  const blurField = (evt, fieldName, fieldProps) => {
    fieldProps.onBlur(evt);
  };
  const changeField = (evt, fieldName, fieldProps) => {
    if (formErrors[fieldName]) {
      clearErrors(`user.${fieldName}`);
    }
    fieldProps.onChange(evt);
  };
  const emailProps = register('email', {
    validate: (value) => validateTalentProfileField('email', value),
  });
  const submitHandler = handleSubmit(signUp);
  const throttledSubmitHandler = useCallback(
    throttle((evt) => submitHandler(evt), 1000, { leading: true }),
    []
  );

  let scaleValues = [0.4, 0.5, 0.6];
  let offsetValues = ['35%', '15%', '-5%'];
  let topValues = ['-30%', '-20%', '-5%'];
  if (document.body.offsetWidth > 460) {
    scaleValues = [0.5, 0.65, 0.8];
    offsetValues = ['45%', '22.5%', 0];
    topValues = ['-20%', '-10%', '10%'];
  }
  if (document.body.offsetWidth > 600) {
    if (isYukiVariant) {
      scaleValues = [0.5, 0.65, 0.8];
      offsetValues = ['55%', '33%', '3%'];
      topValues = ['-5%', 0, '5%'];
    } else {
      scaleValues = [0.6, 0.8, 1];
      offsetValues = ['60%', '38%', '10%'];
      topValues = ['-10%', 0, '10%'];
    }
  }

  return (
    <form
      action="#"
      onSubmit={(evt) => {
        evt.preventDefault();
        setIsFormSubmitting(true);
        throttledSubmitHandler(evt);
      }}
      ref={formRef}
    >
      <Onboarding
        autoSpacedStepContents={!isYukiVariant}
        currentStep={stepNumber}
        flushWrapperSides
        isNextDisabled={isFormSubmitting || Boolean(formErrors.email)}
        nextAction={nextAction}
        nextButtonSubmits
        validateBeforeSubmit={() => false}
      >
        <ToasterContainer />
        <Center>
          <OnboardingTitle
            $reducedFontSize={isYukiVariant}
            $additionalTopSpacing={isYukiVariant}
          >
            {title}
          </OnboardingTitle>
          <SidePaddingWrapper>
            <StatefulInput
              caption={formErrors.email && formErrors.email.message}
              collapseCaptionPadding
              initialState={{ value: initialValues.email }}
              label="Email Address"
              type="email"
              inputMode="email"
              {...emailProps}
              onBlur={(evt) => blurField(evt, 'email', emailProps)}
              onChange={(evt) => changeField(evt, 'email', emailProps)}
              error={!!formErrors.email}
            />
          </SidePaddingWrapper>
          {formErrors.email &&
            formErrors.email.message === 'has already been taken' && (
              <Button
                kind="minimal"
                onClick={() => {
                  window.location = '/users/sign_in';
                }}
              >
                Sign In?
              </Button>
            )}

          <CardsContainer>
            <CardsContainerBackground $showWideBackground={isYukiVariant}>
              <JobCard
                animationDuration="1s"
                hospitalLogo={yaleLogo}
                hospitalName="Yale New Haven Health"
                scale={scaleValues[0]}
                rotation="-8deg"
                rightOffset={offsetValues[0]}
                top={topValues[0]}
                jobTitle="Operating Room / Surgical"
                bonus="$8000"
                hasRelocationAssistance
                location="New Haven, CT"
                recruiterName="David"
                message="Hi! There's an..."
              />
              <JobCard
                animationDuration="1.5s"
                hospitalLogo={northwellLogo}
                hospitalName="Northwell Health"
                scale={scaleValues[1]}
                rotation="4deg"
                rightOffset={offsetValues[1]}
                top={topValues[1]}
                jobTitle="Emergency Department"
                hasRelocationAssistance
                location="New York, NY"
                recruiterName="Sara"
                message="Hi! I love your..."
              />
              <JobCard
                animationDuration="2s"
                hospitalLogo={cedarsLogo}
                hospitalName="Cedars Sinai"
                scale={scaleValues[2]}
                rotation="14deg"
                rightOffset={offsetValues[2]}
                top={topValues[2]}
                jobTitle="Medical / Surgical Coordinator"
                bonus="$10,000"
                hasRelocationAssistance
                location="Los Angeles, CA"
                recruiterName="Charu"
                message="Hi! We have full... "
              />
            </CardsContainerBackground>
          </CardsContainer>
          {isYukiVariant && (
            <OnboardingTestimonial
              author="Lisa Marie, Emergency RN"
              quote="Very good at matching your profile and potential employers. I got my results quickly, and got the job I wanted."
            />
          )}
          <FooterText>
            By signing up you agree to Incredible Health&apos;s{' '}
            <a
              href="https://www.incrediblehealth.com/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.incrediblehealth.com/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </FooterText>
        </Center>
      </Onboarding>
    </form>
  );
};

OnboardingEmail.propTypes = {
  nextAction: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  urlQuery: PropTypes.object,
};

export default OnboardingEmail;
