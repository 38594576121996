import { styled, withStyle, Theme } from 'shared/components/ihcl/styled';
import { StyledBaseButton } from 'shared/components/ihcl/button';
import {
  ColorSwatchGrapeIcon,
  ColorSwatchKaleIcon,
  ColorSwatchOceanIcon,
  ColorSwatchRustIcon,
  ColorSwatchSlateIcon,
} from 'shared/components/ihcl/icon';
import { ModalBody } from 'shared/components/ihcl/modal';
import PropTypes from 'prop-types';
import React from 'react';

export const getColorPickerIcon = (colorScheme) => {
  switch (colorScheme) {
    case 'Grape':
      return <ColorSwatchGrapeIcon />;
    case 'Kale':
      return <ColorSwatchKaleIcon />;
    case 'Ocean':
      return <ColorSwatchOceanIcon />;
    case 'Rust':
      return <ColorSwatchRustIcon />;
    case 'Slate':
      return <ColorSwatchSlateIcon />;
    default:
      return colorScheme;
  }
};

export const ActionButtonWrapper = styled('div', ({ $theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  backgroundColor: $theme.colors.white,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: $theme.lighting.shadow450,
}));

export const BottomButtonWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: $theme.sizing.unit24,
  rowGap: $theme.sizing.unit12,
  position: 'fixed',
  bottom: 0,
  paddingBottom: $theme.sizing.unit40,
  left: $theme.sizing.unit24,
  width: `calc(100% - ${$theme.sizing.unit48})`,
  backgroundColor: $theme.colors.white,
  boxShadow: `${$theme.colors.white} 0 -5px 10px 0`,
}));

export const Card = styled('div', ({ $theme }) => ({
  color: $theme.colors.accent80,
  backgroundColor: 'rgba(20, 20, 42, 0.03)',
  borderRadius: $theme.sizing.unit8,
  marginRight: $theme.sizing.unit16,
  marginLeft: $theme.sizing.unit16,
  paddingTop: $theme.sizing.unit12,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit12,
  paddingLeft: $theme.sizing.unit16,
}));

export type ColorThemeButtonStyledProps = {
  $isSelected: boolean;
};

export const ColorThemeButtonStyled = withStyle<
  typeof StyledBaseButton,
  ColorThemeButtonStyledProps
>(StyledBaseButton, ({ $theme, $isSelected }) => ({
  backgroundColor: 'transparent',
  paddingLeft: '2px',
  paddingRight: '2px',
  paddingTop: '2px',
  paddingBottom: '2px',
  borderTopLeftRadius: '2px',
  borderTopRightRadius: '2px',
  borderBottomRightRadius: '2px',
  borderBottomLeftRadius: '2px',
  boxShadow: `${$theme.colors.borderSelected} 0px 0px 0px ${
    $isSelected ? '1px' : 0
  } inset`,
}));

export const ColorThemeButton = ({ ...props }) => {
  const icon = getColorPickerIcon(props.children);
  return (
    <ColorThemeButtonStyled $isSelected={props.kind === 'primary'} {...props}>
      {icon}
    </ColorThemeButtonStyled>
  );
};

ColorThemeButton.propTypes = {
  children: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
};
export const ColorThemeFieldContainer = styled('div', () => ({
  paddingTop: '8px',
  paddingBottom: '8px',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
}));

export const ColorThemeFieldWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: '40px',
}));

export const ColorThemeFieldLabelWrapper = withStyle(
  ColorThemeFieldWrapper,
  () => ({
    justifyContent: 'right',
  })
);

export const DutiesActions = styled('div', ({ $theme }) => ({
  marginLeft: $theme.sizing.unit8,
  borderLeft: `1px solid ${$theme.colors.grayLine}`,
  paddingTop: $theme.sizing.unit8,
  paddingBottom: $theme.sizing.unit8,
  paddingLeft: $theme.sizing.unit8,
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.unit16,
  justifyContent: 'space-between',
}));

export const DutiesButtonText = styled('div', {
  width: '100%',
  textAlign: 'left',
});

export const DutiesWrapper = styled('div', ({ $theme }) => ({
  marginBottom: $theme.sizing.unit16,
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.unit8,
}));

export const EditFieldsWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.unit24,
  paddingLeft: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit16,
}));

export const EditSectionWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
  marginTop: '-10px',
  marginRight: '-10px',
  marginLeft: '-10px',
});

export const ExperienceDescription = styled('div', {
  whiteSpace: 'pre-wrap',
});

export const ExperienceEditWrapper = styled('div', ({ $theme }) => ({
  borderRadius: $theme.sizing.unit8,
  paddingTop: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit12,
  paddingBottom: $theme.sizing.unit16,
  paddingLeft: $theme.sizing.unit12,
  marginBottom: $theme.sizing.unit16,
  backgroundColor: $theme.colors.gray00,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
}));

export const ExperienceWrapper = styled('div', {
  marginBottom: '16px',
});

export const Hideable = styled('div', ({ $hidden }: any) => ({
  ...($hidden ? { display: 'none' } : null),
}));

export const InnerCard = styled(
  'div',
  ({ $theme, $hasError }: { $theme: Theme; $hasError: boolean }) => ({
    backgroundColor: $theme.colors.grayBackground,
    border: $hasError ? `1px solid ${$theme.colors.negative}` : 'none',
    borderRadius: $theme.sizing.unit8,
    paddingTop: $theme.sizing.unit24,
    paddingRight: $theme.sizing.unit24,
    paddingBottom: $theme.sizing.unit24,
    paddingLeft: $theme.sizing.unit24,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: $theme.sizing.unit16,
  })
);

export const InnerCardTextGroup = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.unit8,
}));

export const PreviewIframe = styled('iframe', {
  width: '100%',
  aspectRatio: '8.5 / 11',
});

export const QualificationsList = styled('ul', {
  listStyleType: 'none',
  paddingInlineStart: 0,
});

export const QualificationsSet = styled('div', {
  minWidth: '300px',
});

export const QualificationsSetWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: $theme.sizing.unit16,
  justifyContent: 'space-between',
}));

export const ResponsibilityTipsButtonWrapper = styled('div', ({ $theme }) => ({
  paddingTop: $theme.sizing.unit16,
}));

export const ResumeModalBody = withStyle(ModalBody, ({ $theme }) => ({
  ...$theme.typography.DisplayLarge,
  color: $theme.colors.black,
  paddingTop: '24px',
  paddingBottom: '48px',
  maxWidth: '750px',
  alignSelf: 'center',
}));

export const ResumeSection = styled('div', {});

export const ResumeTipsWrapper = styled('div', {
  paddingBottom: '110px', // Height of the 2 floating buttons plus some padding
});

export const SectionContents = styled('div', ({ $theme }) => ({
  textAlign: 'left',
  paddingTop: $theme.sizing.unit24,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit24,
  paddingLeft: $theme.sizing.unit16,
}));

export const SectionDataErrorText = styled('span', ({ $theme }) => ({
  color: $theme.colors.negative,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: '0.5px',
  marginLeft: '4px',
}));

export declare interface SectionDataStyledWrapperProps {
  $error?: boolean;
  $theme: Theme;
}

export const SectionDataStyledWrapper = styled(
  'div',
  ({ $theme, $error }: SectionDataStyledWrapperProps) => {
    const styles = {
      lineHeight: '150%',
    };

    if ($error) {
      return {
        ...styles,
        ...{
          marginTop: '4px',
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderRadius: '4px',
          background: $theme.colors.inputFillError,
          paddingTop: $theme.sizing.inputMinimalPadding,
          paddingRight: $theme.sizing.inputMinimalPadding,
          paddingBottom: $theme.sizing.inputMinimalPadding,
          paddingLeft: $theme.sizing.inputMinimalPadding,
        },
      };
    }
    return styles;
  }
);

export const SectionDivider = styled('hr', ({ $theme }) => ({
  borderColor: $theme.colors.grayLine,
}));

export declare interface SectionTitleProps {
  $noHorizontalPadding?: boolean;
  $theme: Theme;
}

export const SectionTitle = styled(
  'h3',
  ({ $noHorizontalPadding = false, $theme }: SectionTitleProps) => ({
    paddingRight: $noHorizontalPadding ? 0 : $theme.sizing.unit16,
    paddingLeft: $noHorizontalPadding ? 0 : $theme.sizing.unit16,
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: $theme.colors.primary,
  })
);

export const SectionTitleGroup = styled('div', ({ $theme }) => ({
  paddingBottom: $theme.sizing.unit24,
}));

export const SimpleCard = styled(
  'div',
  ({
    $theme,
    $backgroundColor,
    $margin = null,
    $verticalLayout = false,
  }: {
    $theme: Theme;
    $backgroundColor: string;
    $margin?: string;
    $verticalLayout?: boolean;
  }) => ({
    display: 'flex',
    flexDirection: $verticalLayout ? 'column' : 'row',
    rowGap: $theme.sizing.unit16,
    alignItems: 'center',
    backgroundColor: $backgroundColor || $theme.colors.seaGreen10,
    borderRadius: $theme.sizing.unit8,
    paddingTop: $theme.sizing.unit16,
    paddingRight: $theme.sizing.unit16,
    paddingBottom: $theme.sizing.unit16,
    paddingLeft: $theme.sizing.unit16,
    marginTop: $margin || $theme.sizing.unit16,
    marginRight: $margin || 0,
    marginBottom: $margin || $theme.sizing.unit16,
    marginLeft: $margin || 0,
  })
);

export const SubTitle = styled('div', {});

export const TextAreaInputWrapper = styled('div', {
  lineHeight: '130%',
});

export const TextAreaTitleWrapper = styled('div', {
  textTransform: 'uppercase',
});

export const Title = styled('h2', ({ $theme }) => ({
  color: $theme.colors.black,
  fontSize: '24px',
  fontWeight: 600,
  marginTop: 0,
}));

export const TitleImage = styled('img', {
  height: '90px',
});

export const TitleSection = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  rowGap: '16px',
});

export const Wrapper = styled('div', {
  textAlign: 'start',
});
