const getInterviewsWithStatus = (state, status, interviewRequest) =>
  state.interviews?.filter(
    (interview) =>
      (!interviewRequest ||
        interview.interview_request_id === interviewRequest.id) &&
      interview.status === status
  ) || [];

export const getInterviewsForInterviewRequest = (state, interviewRequest) =>
  state.interviews?.filter(
    (interview) =>
      !interviewRequest ||
      interview.interview_request_id === interviewRequest.id
  ) || [];

const getHasRescheduledInterviews = (state, interviewRequest) =>
  getInterviewsWithStatus(state, 'rescheduled', interviewRequest).length > 0;

const getHasScheduledInterviews = (state, interviewRequest) =>
  getInterviewsWithStatus(state, 'scheduled', interviewRequest).length > 0;

export const getIsAwaitingReschedule = (state, interviewRequest) =>
  getHasRescheduledInterviews(state, interviewRequest) &&
  !getHasScheduledInterviews(state, interviewRequest);

const isToday = (dateTimeString) => {
  const currentDate = new Date();
  const inputDate = new Date(dateTimeString);

  return (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  );
};

export const getHasScheduledInterviewToday = (state, interviewRequest) =>
  getInterviewsWithStatus(state, 'scheduled', interviewRequest).some(
    (interview) => isToday(interview.scheduled_at)
  );
