import createSentryMiddleware from 'redux-sentry-middleware';

export default function () {
  if (typeof Sentry !== 'object') return [];

  return [
    // eslint-disable-next-line no-undef
    createSentryMiddleware(Sentry, {
      // our redux state is large, never send it to Sentry
      stateTransformer: () => null,
    }),
  ];
}
