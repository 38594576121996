import React, { FunctionComponent } from 'react';

import { styled, withStyle, Theme } from 'shared/components/ihcl/styled';
import {
  ANCHOR,
  Drawer as BaseDrawer,
  SIZE as BASE_SIZE,
  StyledClose as BaseStyledClose,
  StyledDrawerBody as BaseStyledDrawerBody,
  StyledDrawerContainer as BaseStyledDrawerContainer,
  StyledRoot as BaseStyledRoot,
} from 'baseui/drawer';
import {
  AnchorProp,
  CloseSource,
  DrawerOverrides,
  SizeProp,
} from 'baseui/drawer/types';
import { Button } from 'shared/components/ihcl/button';
import { CloseIcon } from 'shared/components/ihcl/icon';

export { ANCHOR } from 'baseui/drawer';

const SIZE = Object.fromEntries(
  Object.entries(BASE_SIZE).filter(([key]) => key !== 'default')
);

const Root = withStyle(BaseStyledRoot, {
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const DrawerBody = withStyle(
  BaseStyledDrawerBody,
  ({
    $drawerPadding,
    $theme,
  }: {
    $drawerPadding?: 'smallX' | 'smallY' | 'default';
    $theme: Theme;
  }) => ({
    paddingTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: '22px',
    paddingLeft:
      $drawerPadding === 'smallX' ? $theme.sizing.unit16 : $theme.sizing.unit24,
    paddingRight:
      $drawerPadding === 'smallX' ? $theme.sizing.unit16 : $theme.sizing.unit24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);

const DrawerInnerBody = styled(
  'div',
  ({
    $drawerPadding,
    $theme,
  }: {
    $drawerPadding?: 'smallX' | 'smallY' | 'default';
    $theme: Theme;
  }) => ({
    flexGrow: 1,
    paddingBottom:
      $drawerPadding === 'smallY' ? $theme.sizing.unit16 : $theme.sizing.unit64,
    paddingTop: $theme.sizing.unit16,
  })
);

const DrawerContainer = withStyle(
  BaseStyledDrawerContainer,
  ({ $size, $theme }: { $size: keyof typeof SIZE; $theme: Theme }) => ({
    borderStartEndRadius: $theme.sizing.unit24,
    borderStartStartRadius: $theme.sizing.unit24,
    height: $size === SIZE.auto ? null : 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
  })
);

const DrawerModalContainer = withStyle(DrawerContainer, ({ $theme }) => ({
  [$theme.mediaQuery.md]: {
    top: 'auto',
    left: 'inherit',
    bottom: 'auto',
    right: 'inherit',
    maxWidth: '500px',
    height: 'auto',
    maxHeight: `calc(100vh - ${$theme.sizing.unit128})`,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderEndStartRadius: $theme.sizing.unit24,
    borderEndEndRadius: $theme.sizing.unit24,
    marginBottom: $theme.sizing.unit64,
    marginTop: $theme.sizing.unit64,
  },
}));

const CloseComponent = withStyle(BaseStyledClose, ({ $theme }) => ({
  top: $theme.sizing.unit24,
  width: $theme.sizing.unit56,
  height: $theme.sizing.unit56,
  paddingTop: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit16,
  paddingLeft: $theme.sizing.unit16,
  zIndex: 2,
}));

const Close = (props) => (
  <CloseComponent {...props}>
    <CloseIcon size={24} />
  </CloseComponent>
);

const DrawerTitle = styled('h2', ({ $theme }) => ({
  ...$theme.typography.font550,
  fontSize: $theme.sizing.unit24,
  paddingBottom: $theme.sizing.unit8,
  position: 'sticky',
  top: '0',
  paddingTop: $theme.sizing.unit16,
  marginTop: '0',
  marginBottom: '0',
  backgroundColor: $theme.colors.white,
  boxShadow: 'white 0px 10px 16px 0px',
  marginLeft: `-${$theme.sizing.unit16}`,
  marginRight: `-${$theme.sizing.unit16}`,
  paddingLeft: $theme.sizing.unit16,
  paddingRight: `calc(${$theme.sizing.unit24} + ${$theme.sizing.unit48})`,
  zIndex: 1,
}));

const DrawerSubTitle = styled('div', ({ $theme }) => ({
  ...$theme.typography.DisplaySmall,
  lineHeight: '120%',
  color: $theme.colors.bodyCopyGray,
}));

const DrawerActions = styled(
  'div',
  ({ $theme, $size }: { $size: keyof typeof SIZE; $theme: Theme }) => ({
    display: 'flex',
    backgroundColor: $theme.colors.white,
    paddingTop: $theme.sizing.unit12,
    borderTop:
      $size === SIZE.full
        ? `1px solid ${$theme.colors.gray00}`
        : `1px solid ${$theme.colors.white}`,
    flexDirection: $size === SIZE.full ? 'row-reverse' : 'column',
    marginLeft: $size === SIZE.full ? `-${$theme.sizing.unit24}` : 0,
    marginRight: $size === SIZE.full ? `-${$theme.sizing.unit24}` : 0,
    paddingLeft: $size === SIZE.full ? $theme.sizing.unit12 : 0,
    paddingRight: $size === SIZE.full ? $theme.sizing.unit12 : 0,
    [$theme.mediaQuery.md]: {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    rowGap: $theme.sizing.unit12,
    columnGap: $theme.sizing.unit8,
    marginTop: $theme.sizing.unit24,
    position: 'sticky',
    bottom: 0,
  })
);

export const PrimaryButton = (props: any) => (
  <Button kind="primary" color="alternate" style={{ flexGrow: 1 }} {...props} />
);

export const SecondaryButton = (props: any) => (
  <Button kind="tertiary" color="alternate" {...props} />
);

type DrawerProps = {
  actions?: React.ReactNode[];
  drawerPadding?: 'smallX' | 'smallY' | 'default';

  /** Sets whether the Drawer should be displayed by easing in and out */
  animate?: boolean;
  isOpen: boolean;
  /** If true, focus will shift to the first interactive element within the drawer.
   * If false, the drawer container itself will receive focus.
   * Moving focus into a newly opened drawer is important for accessibility purposes, so please be careful!
   */
  autoFocus?: boolean;
  /** Renders all drawer content for SEO purposes regardless of drawer isOpen state */
  renderAll?: boolean;
  /** Where to mount the modal */
  mountNode?: HTMLElement;
  /** Drawer content. The children-as-function API may be preferable
   * for performance reasons (wont render until opened) */
  children?: React.ReactNode | (() => React.ReactNode);
  /** Whether the modal should be closeable by the user
   *  (either via escape, backdrop click, etc). You can set this to
   * false if your modal has an action that the user must take before closing. */
  closeable?: boolean;
  onClose?: (a: { closeSource?: CloseSource }) => unknown;
  overrides?: DrawerOverrides;
  /** Controls the size of the modal (primarily width).
   * Can be a SIZE constant or css width property value. */
  size?: SizeProp;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
  anchor?: AnchorProp;
  /** Whether the backdrop should be present */
  showBackdrop?: boolean;
  /** Function to be called when backdrop is clicked */
  onBackdropClick?: (e: Event) => unknown;
  onEscapeKeyDown?: (e: Event) => unknown;
};

// eslint-disable-next-line react/require-default-props
const Drawer: FunctionComponent<DrawerProps> = ({
  actions = null,
  drawerPadding = 'default',
  subTitle = null,
  title = null,
  ...props
}) => {
  const childrenWithTitle = (
    <>
      <DrawerTitle>
        {title || <>&nbsp;</>}
        {subTitle && <DrawerSubTitle>{subTitle}</DrawerSubTitle>}
      </DrawerTitle>
      <DrawerInnerBody $drawerPadding={drawerPadding}>
        {props.children}
      </DrawerInnerBody>
      {actions && <DrawerActions $size={props.size}>{actions}</DrawerActions>}
    </>
  );

  return (
    <BaseDrawer
      {...props}
      overrides={{
        Root: {
          component: Root,
        },
        DrawerBody: {
          component: DrawerBody,
          props: {
            $drawerPadding: drawerPadding,
          },
        },
        DrawerContainer: {
          component: DrawerModalContainer,
          props: { $size: props.size },
        },
        Close,
      }}
    >
      {childrenWithTitle}
    </BaseDrawer>
  );
};

Drawer.defaultProps = {
  actions: null,
  anchor: ANCHOR.bottom,
  animate: true,
  autoFocus: true,
  children: null,
  closeable: true,
  drawerPadding: 'default',
  renderAll: false,
  mountNode: null,
  onBackdropClick: null,
  onClose: () => {},
  onEscapeKeyDown: null,
  overrides: null,
  showBackdrop: true,
  size: SIZE.default,
  subTitle: null,
  title: null,
};

export { SIZE, Drawer };
