import { SIZE, ROLE } from 'baseui/modal';
import { useStyletron } from 'shared/components/ihcl/styled';
import capitalize from 'just-capitalize';
import clone from 'just-clone';
import get from 'just-safe-get';
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  PropsWithChildren,
  Fragment,
} from 'react';
import { useHistory, useLocation } from 'react-router';

import { connect } from 'react-redux';
import resumeWizardIllustration from 'images/illustrations/resume-wizard.png';
import { parsePhoneNumber } from 'shared/helpers/parsers';
import { updateTalentProfileComplete } from 'shared/actions/talentProfileActions';
import { degreeTypeOptions } from 'shared/constants/selectOptions';
import { trackComponentView, trackEvent } from 'shared/helpers/tracking';
import {
  AppContext,
  useAppContextFeatureFlags,
} from 'shared/components/AppProvider';
import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { Drawer, ANCHOR } from 'shared/components/ihcl/drawer';
import { TalentModal } from 'shared/components/ihcl/modal';
import {
  MonthPicker,
  dateMatchYYYYMM,
  getMonthTextFromDate,
  getDateFromMonthText,
} from 'shared/components/ihcl/month_picker';
import { Select } from 'shared/components/ihcl/select';
import { Tag } from 'shared/components/ihcl/tag';
import { Text } from 'shared/components/ihcl/text';
import { Textarea } from 'shared/components/ihcl/textarea';
import {
  AlertTriangleIcon,
  GlassesIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  WandIcon,
} from 'shared/components/ihcl/icon';
import { Spinner } from 'shared/components/ihcl/spinner';
import { toaster } from 'shared/components/ihcl/toast';
import constants from 'shared/constants/constants.json';
import { splitWorkExperienceDuties } from 'shared/helpers/textParsing';
import { TalentProfile } from 'talent/types';

import {
  useGenerateResume,
  useUpdateTalentProfile,
} from 'api/talent/hooks/talentProfile';

import { locationFromGooglePlace } from 'registration/helpers/talentProfileConversions';
import { useGoogleAutocompleteService } from 'shared/helpers/googlePlaces';
import { ActiveLicenses } from '../../registration/components/new_pages/OnboardingLicenses';
import { CertificationsEditor } from '../../registration/components/new_pages/OnboardingCredentialsAlt';
import ButtonSelector from './resume/ButtonSelector';
import {
  ActionButtonWrapper,
  ColorThemeButton,
  ColorThemeFieldContainer,
  ColorThemeFieldWrapper,
  ColorThemeFieldLabelWrapper,
  DutiesActions,
  DutiesButtonText,
  DutiesWrapper,
  EditFieldsWrapper,
  EditSectionWrapper,
  ExperienceEditWrapper,
  ExperienceDescription,
  ExperienceWrapper,
  Hideable,
  InnerCard,
  InnerCardTextGroup,
  PreviewIframe,
  QualificationsList,
  QualificationsSet,
  QualificationsSetWrapper,
  ResumeModalBody,
  ResumeSection,
  SectionDataErrorText,
  SectionDataStyledWrapper,
  SectionDivider,
  SectionTitle,
  SubTitle,
  TextAreaInputWrapper,
  TextAreaTitleWrapper,
  Title,
  TitleImage,
  TitleSection,
  Wrapper,
} from './TalentProfileResumeStyledElements';
import TalentProfileResumeResponsibilitiesModal from './TalentProfileResumeResponsibilitiesModal';

export const MODAL_PATH = '#resume';

type GenericObject = {
  [key: string]: any;
};

type StateObject = {
  state_id: number;
  compact: boolean | null;
  hasCompactLicense?: boolean;
};

type StateFromAppContext = {
  id: number;
  name: string;
};

const degreeTypeMap = degreeTypeOptions.reduce(
  (map, option) => ({
    ...map,
    [option.value]: option.label,
  }),
  {}
);

const rootValidationFields = {
  phone: 'validatePhone',
  first_name: 'required',
  last_name: 'required',
};

const weValidationFields = {
  company_name: 'required',
  location: 'required',
  duties: 'required',
  job_title: 'required',
  start_date: 'startDateEndDateValid',
  end_date: 'startDateEndDateValid',
};

const teValidationFields = {
  institution_name: 'required',
  major: 'required',
  degree_type: 'required',
};

const weFieldConfig = {
  company_name: '',
  duties: '',
  start_date: null,
  end_date: null,
  currently_there: false,
  job_title: '',
};

const teFieldConfig = {
  institution_name: '',
  major: '',
  degree_type: '',
  graduation_date: null,
  notes: '',
};

const placeholderFieldsConfig = {
  location: 'City, State',
};

const getFieldLabel = (field) => field.split('_').map(capitalize).join(' ');

const getMonthYearFromDate = (val) => {
  const dateVal = val?.match(dateMatchYYYYMM);

  if (dateVal && dateVal.length > 0) {
    return dateVal[0];
  }
  return null;
};

const getFieldValue = (
  field,
  fieldPath,
  talentProfile,
  modifiedFields,
  returnFieldObj = false
) => {
  let fieldObj = fieldPath.reduce((acc, val) => acc[val], talentProfile);
  let returnFieldValue;

  if (!fieldObj) {
    // Handle new items that are in modifiedFields, but not talentProfile.
    // For example, a newly added item in `work_experiences`
    fieldObj = fieldPath.reduce((acc, val) => acc[val], modifiedFields);
  }
  const fieldValue = fieldObj[field];
  const modifiedCheckObj = fieldPath.reduce(
    (acc, val) => (acc ? acc[val] : null),
    modifiedFields
  );
  if (
    modifiedCheckObj &&
    (Object.prototype.hasOwnProperty.call(modifiedCheckObj, field) ||
      modifiedCheckObj[field])
  ) {
    if (returnFieldObj) {
      return { fieldValue: modifiedCheckObj[field], fieldObj };
    }
    return modifiedCheckObj[field];
  }
  returnFieldValue = fieldValue;
  if (field === 'phone') {
    returnFieldValue = fieldValue.text;
  }

  if (field === 'currently_there') {
    returnFieldValue =
      typeof fieldValue !== 'undefined'
        ? fieldValue
        : fieldObj.end_date === null;
  }
  if (returnFieldObj) {
    return { fieldValue: returnFieldValue, fieldObj };
  }
  return returnFieldValue;
};

const validationFunctions = {
  required: (val) => {
    if (!val) {
      return { error: 'Required' };
    }
    return { error: null };
  },

  validatePhone: (val) => {
    if (val.text) {
      return { error: null, updated_val: val };
    }
    const parsedNumber = parsePhoneNumber(val);
    if (parsedNumber) {
      if (parsedNumber === val) {
        // No-op: if no error and unchanged, phone number is already in the
        // correct format, so no need to update it
        return { error: null, updated_val: null };
      }
      return { error: null, updated_val: parsedNumber };
    }
    const errMsg =
      'Oops, this phone number appears to be invalid. If this ' +
      'is not a US number please make sure to start it with a + and ' +
      'your country code.';
    return { error: errMsg };
  },

  startDateEndDateValid: (
    val,
    field,
    fieldPath = [],
    talentProfile,
    modifiedFields = {}
  ) => {
    const currentlyThere = getFieldValue(
      'currently_there',
      fieldPath,
      talentProfile,
      modifiedFields
    );

    if (field === 'start_date' || !currentlyThere) {
      const result = validationFunctions.required(val);
      if (result.error) {
        return result;
      }
    }

    const startDate =
      field === 'start_date'
        ? val
        : getFieldValue('start_date', fieldPath, talentProfile, modifiedFields);
    const endDate =
      field === 'end_date'
        ? val
        : getFieldValue('end_date', fieldPath, talentProfile, modifiedFields);

    if (!currentlyThere && startDate && endDate) {
      if (getDateFromMonthText(startDate) > getDateFromMonthText(endDate)) {
        return {
          error:
            field === 'start_date'
              ? 'Start date cannot be after end date'
              : 'End date cannot be before start date',
        };
      }
    }

    return { error: null };
  },
};

const validateAllFields = ({
  fieldObj,
  validateForResumeGeneration = true,
}) => {
  const errorObj = {
    phone: null,
    work_experiences: [],
    talent_education_experiences: [],
    resume_generation_errors: {
      talent_education_experiences_empty: null,
      work_experiences_empty: null,
      work_experience_fields_missing: null,
      phone_number_missing: null,
    },
  };

  Object.entries(rootValidationFields).forEach(([field, validationMethod]) => {
    const result = validationFunctions[validationMethod](
      fieldObj[field],
      field,
      fieldObj
    );

    if (result.error) {
      errorObj[field] = result.error;
    }
  });

  ['work_experiences', 'talent_education_experiences'].forEach((arrayName) => {
    if (fieldObj[arrayName]) {
      fieldObj[arrayName].forEach((experience, index) => {
        const fieldConfig =
          arrayName === 'work_experiences' ? weFieldConfig : teFieldConfig;
        if (
          !experience._destroy &&
          !(!experience.isTouched && experience === fieldConfig)
        ) {
          const expErrorObj = {};

          const validationFields =
            arrayName === 'work_experiences'
              ? weValidationFields
              : teValidationFields;
          Object.entries(validationFields).forEach(
            ([field, validationMethod]) => {
              const expResult = validationFunctions[validationMethod](
                experience[field],
                field,
                [arrayName, index],
                fieldObj
              );
              if (expResult.error) {
                expErrorObj[field] = expResult.error;
              }
            }
          );
          if (Object.keys(expErrorObj).length > 0) {
            errorObj[arrayName][index] = expErrorObj;
          }
        }
      });
    }
  });

  if (validateForResumeGeneration) {
    errorObj.resume_generation_errors.talent_education_experiences_empty =
      !fieldObj.talent_education_experiences ||
      fieldObj.talent_education_experiences.length === 0;
    errorObj.resume_generation_errors.work_experiences_empty =
      !fieldObj.work_experiences || fieldObj.work_experiences.length === 0;
    errorObj.resume_generation_errors.work_experience_fields_missing =
      errorObj.work_experiences.some((we) => we.location || we.duties);
    errorObj.resume_generation_errors.phone_number_missing = !!errorObj.phone;
  }

  return errorObj;
};

type SectionDataProps = {
  children?: React.ReactNode;
  error?: boolean;
  errorText?: string;
};

// eslint-disable-next-line react/require-default-props
const SectionData = ({
  // eslint-disable-next-line react/require-default-props
  children,
  // eslint-disable-next-line react/require-default-props
  error,
  // eslint-disable-next-line react/require-default-props
  errorText,
}: SectionDataProps): JSX.Element => {
  const [, theme] = useStyletron();
  return (
    <SectionDataStyledWrapper $error={error}>
      {error ? (
        <>
          <AlertTriangleIcon color={theme.colors.negative} />
          <SectionDataErrorText>{errorText}</SectionDataErrorText>
        </>
      ) : (
        <>{children}</>
      )}
    </SectionDataStyledWrapper>
  );
};

SectionData.defaultProps = {
  children: null,
  error: false,
  errorText: null,
};

// eslint-disable-next-line react/require-default-props
const EditSection = ({
  dispatch,
  title,
  fields,
  // nurseSpecialtyDescriptionsMap,
  setIsEditing,
  talentProfile,
  updateTalentProfileCompleteAction,
  // eslint-disable-next-line react/require-default-props
  next,
}) => {
  const [, theme] = useStyletron();
  const [modifiedFields, setModifiedFields] = useState({});
  const [errors, setErrors] = useState({});
  const [fieldSection, setFieldSection] = useState(null);
  const [removedDuty, setRemovedDuty] = useState(null);
  const [responsibilityText, setResponsibilityText] = useState(null);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [
    resumeResponsibilitiesModalIdOpen,
    setResumeResponsibilitiesModalIdOpen,
  ] = useState(null);
  const { states: statesOrderedMap } = useContext(AppContext) as any;
  const featureFlags = useAppContextFeatureFlags();
  const states: StateFromAppContext[] = statesOrderedMap.toArray();

  useEffect(() => {
    trackComponentView('talent.resume_editor_modal_edit', {
      'Section Title': title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: doUpdateTalentProfile, isLoading: isSaving } =
    useUpdateTalentProfile({
      onSuccess: (resp) => {
        dispatch(updateTalentProfileCompleteAction(resp));
        setIsEditing(next);
        setModifiedFields({});
        trackEvent('talent.resume_editor_modal_edit.save', {
          'Section Title': title,
        });
      },
      onError: (err: any) => {
        if (err && err.response) {
          err.response.json().then((response) => {
            setErrors(response.errors);
          });
        }
      },
    });

  const autocompleteService = useGoogleAutocompleteService();

  const isTalentResumeWizardAiResponsibilities =
    featureFlags &&
    featureFlags.includes('talent_resume_wizard_ai_responsibilities');

  const updateModifiedField = ({
    inputValue,
    field,
    fieldValue,
    fieldPath,
  }) => {
    const newModifiedFields = {
      ...modifiedFields,
    };
    if (fieldValue === inputValue) {
      delete newModifiedFields[field];
    } else if (fieldPath.length > 0) {
      const fieldPathCopy = [...fieldPath];
      const currentPath = [];
      const isExperienceProperty =
        fieldPath.includes('work_experiences') ||
        fieldPath.includes('talent_education_experiences');
      let currentFieldObj: GenericObject = newModifiedFields;
      let isLastField = false;
      while (fieldPathCopy.length > 0) {
        const currentField = fieldPathCopy[0];
        fieldPathCopy.shift();
        let nextField = fieldPathCopy[0];
        if (nextField === undefined) {
          nextField = field;
          isLastField = true;
        }
        currentPath.push(currentField);
        if (
          !Object.prototype.hasOwnProperty.call(
            currentFieldObj,
            currentField
          ) &&
          !currentFieldObj[currentField]
        ) {
          if (typeof nextField === 'string') {
            currentFieldObj[currentField] = {};
          } else if (typeof nextField === 'number') {
            currentFieldObj[currentField] = currentPath.reduce(
              (acc, val) => acc[val],
              talentProfile
            );
          }
          currentFieldObj = currentFieldObj[currentField];
        } else {
          currentFieldObj = currentFieldObj[currentField];
        }
        if (isLastField) {
          currentFieldObj[field] = inputValue;
        }
        if (isExperienceProperty) {
          currentFieldObj.isTouched = true;
        }
      }
    } else {
      newModifiedFields[field] = inputValue;
    }
    setModifiedFields(newModifiedFields);
  };

  const addWorkExperience = () => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(
        newModifiedFields,
        'work_experiences'
      )
    ) {
      newModifiedFields.work_experiences = [...talentProfile.work_experiences];
    }
    newModifiedFields.work_experiences.push({ ...weFieldConfig });
    setModifiedFields(newModifiedFields);
  };

  const removeWorkExperience = (workExperienceIndex) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(
        newModifiedFields,
        'work_experiences'
      )
    ) {
      newModifiedFields.work_experiences = [...talentProfile.work_experiences];
    }
    // eslint-disable-next-line no-underscore-dangle
    newModifiedFields.work_experiences[workExperienceIndex]._destroy = true;
    setModifiedFields(newModifiedFields);
  };

  const undoRemoveWorkExperience = (workExperienceIndex) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    // eslint-disable-next-line no-underscore-dangle
    delete newModifiedFields.work_experiences[workExperienceIndex]._destroy;
    setModifiedFields(newModifiedFields);
  };

  const addError = (field, error, fieldPath = []) => {
    let modifiedErrors = { ...errors };
    if (fieldPath.length === 2) {
      const arrayName = fieldPath[0];
      const arrayIndex = fieldPath[1];

      if (!modifiedErrors[arrayName]) {
        modifiedErrors = { ...errors, [arrayName]: [] };
      }

      if (!modifiedErrors[arrayName][arrayIndex]) {
        modifiedErrors[arrayName][arrayIndex] = {};
      }

      modifiedErrors[arrayName][arrayIndex][field] = error;

      setErrors({ ...modifiedErrors });
    } else {
      modifiedErrors[field] = error;
      setErrors({ ...modifiedErrors });
    }
  };

  const clearError = (field, fieldPath = []) => {
    const modifiedErrors = { ...errors };

    if (fieldPath.length === 2) {
      const arrayName = fieldPath[0];
      const arrayIndex = fieldPath[1];

      if (get(errors, [arrayName, arrayIndex, field])) {
        const subArr = get(errors, [arrayName, arrayIndex]);
        delete subArr[field];
        modifiedErrors[arrayName][arrayIndex] = subArr;
      }
    } else {
      delete modifiedErrors[field];
    }

    setErrors({ ...modifiedErrors });
  };

  const getErrors = (field, fieldPath = []) => {
    if (errors) {
      if (fieldPath.length === 2) {
        const arrayName = fieldPath[0];
        const arrayIndex = fieldPath[1];

        return get(errors, [arrayName, arrayIndex, field]);
      }

      return errors[field];
    }
    return null;
  };

  const removeEducationExperience = (educationExperienceIndex) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(
        newModifiedFields,
        'talent_education_experiences'
      )
    ) {
      newModifiedFields.talent_education_experiences = [
        ...talentProfile.talent_education_experiences,
      ];
    }
    // eslint-disable-next-line no-underscore-dangle
    newModifiedFields.talent_education_experiences[
      educationExperienceIndex
    ]._destroy = true;
    setModifiedFields(newModifiedFields);
  };

  const undoRemoveEducationExperience = (educationExperienceIndex) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    // eslint-disable-next-line no-underscore-dangle
    delete newModifiedFields.talent_education_experiences[
      educationExperienceIndex
    ]._destroy;
    setModifiedFields(newModifiedFields);
  };

  const addEducationExperience = () => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(
        newModifiedFields,
        'talent_education_experiences'
      )
    ) {
      newModifiedFields.talent_education_experiences = [
        ...talentProfile.talent_education_experiences,
      ];
    }
    newModifiedFields.talent_education_experiences.push({ ...teFieldConfig });
    setModifiedFields(newModifiedFields);
  };

  const removeStateLicense = (stateId) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(newModifiedFields, 'state_licenses')
    ) {
      newModifiedFields.state_licenses = [...talentProfile.state_licenses];
    }
    newModifiedFields.state_licenses = newModifiedFields.state_licenses.filter(
      (state) => state.state_id !== stateId
    );
    setModifiedFields(newModifiedFields);
  };

  const toggleStateCompact = (stateId, isCompactLicense) => {
    const newModifiedFields: GenericObject = { ...modifiedFields };
    if (
      !Object.prototype.hasOwnProperty.call(newModifiedFields, 'state_licenses')
    ) {
      newModifiedFields.state_licenses = [...talentProfile.state_licenses];
    }
    newModifiedFields.state_licenses.forEach((state, i) => {
      if (state.state_id === stateId) {
        newModifiedFields.state_licenses[i].compact = isCompactLicense;
      }
    });
    setModifiedFields(newModifiedFields);
  };

  function handleDatePickerValidation({
    date,
    validationMethod,
    field,
    fieldPath,
    tp,
    modifiedFieldsObj,
  }) {
    const validationRes = validationFunctions[validationMethod](
      date,
      field,
      fieldPath,
      tp,
      modifiedFieldsObj
    );
    if (validationRes.error) {
      addError(field, validationRes.error, fieldPath);
    } else {
      clearError(field, fieldPath);
    }
  }

  const getEditField = ({ field, fieldPath = [], validationMethod = null }) => {
    const { fieldValue, fieldObj } = getFieldValue(
      field,
      fieldPath,
      talentProfile,
      modifiedFields,
      true
    );
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100;
    const maxYear = currentYear + 100;

    // eslint-disable-next-line react/no-unstable-nested-components
    const StateAction = ({ state }: { state: StateObject }) => (
      <Button
        fullWidth
        size="icon"
        kind="minimal"
        onClick={() => {
          removeStateLicense(state.state_id);
        }}
        style={{
          color: theme.colors.bodyCopyGray,
          width: 'auto',
        }}
      >
        <TrashIcon size="20" title="Remove License" />
      </Button>
    );

    // eslint-disable-next-line react/no-unstable-nested-components
    const CompactAction = ({
      // eslint-disable-next-line react/require-default-props
      children,
      state,
    }: PropsWithChildren<{ state: StateObject }>) => (
      <Checkbox
        checked={state.hasCompactLicense}
        onChange={(e) => {
          toggleStateCompact(state.state_id, e.target.checked);
        }}
      >
        {children}
      </Checkbox>
    );

    const error = getErrors(field, fieldPath);
    const hasError = !!error;

    const selectOverrides = {
      Popover: {
        props: {
          overrides: {
            Body: {
              style: {
                zIndex: 1201, // 1 higher than TalentModal zIndex
              },
            },
          },
        },
      },
    };

    const handleLocationInputChange = (evt) => {
      const { value } = evt.currentTarget;
      if (!value || !autocompleteService) {
        return;
      }
      autocompleteService.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: 'us' },
          types: ['(cities)'],
        },
        (results) => {
          if (results) {
            const newLocationValues = results.map(locationFromGooglePlace);
            setLocationSuggestions(newLocationValues);
          }
        }
      );
    };

    let AiResponsibilities: JSX.Element;

    switch (field) {
      case 'work_experiences': {
        if (fieldSection !== field) {
          setFieldSection(field);
        }
        if (fieldValue.length === 0) {
          addWorkExperience();
          return null;
        }
        return (
          <React.Fragment key={field}>
            {fieldValue.map((workExperience, i) => (
              <ExperienceEditWrapper key={workExperience.id || 'new'}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {workExperience._destroy ? (
                  <Button
                    fullWidth
                    size="compact"
                    kind="minimal"
                    onClick={() => {
                      undoRemoveWorkExperience(i);
                    }}
                  >
                    Undo
                  </Button>
                ) : (
                  <>
                    {[
                      'company_name',
                      'job_title',
                      'location',
                      'start_date',
                      'end_date',
                      'currently_there',
                      'duties',
                      'work_experience_specialties',
                    ].map((workExperienceField) =>
                      getEditField({
                        field: workExperienceField,
                        fieldPath: [field, i],
                        validationMethod:
                          weValidationFields[workExperienceField],
                      })
                    )}
                    <Button
                      fullWidth
                      size="compact"
                      kind="minimal"
                      onClick={() => {
                        removeWorkExperience(i);
                      }}
                      style={{
                        color: theme.colors.bodyCopyGray,
                        fontSize: '12px',
                        display: 'flex',
                        columnGap: '4px',
                      }}
                    >
                      <TrashIcon size="20" /> Remove
                    </Button>
                  </>
                )}
              </ExperienceEditWrapper>
            ))}
            <Button
              fullWidth
              kind="outline"
              size="compact"
              onClick={() => {
                addWorkExperience();
              }}
            >
              <PlusIcon style={{ marginRight: '8px' }} />
              {fieldValue.length === 0
                ? 'Add work experience'
                : 'Add another position'}
            </Button>
          </React.Fragment>
        );
      }
      case 'talent_education_experiences':
        if (fieldSection !== field) {
          setFieldSection(field);
        }
        if (fieldValue.length === 0) {
          addEducationExperience();
          return null;
        }
        return (
          <React.Fragment key={field}>
            {fieldValue.map((educationExperience, i) => (
              <ExperienceEditWrapper key={educationExperience.id || 'new'}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {educationExperience._destroy ? (
                  <Button
                    fullWidth
                    key={`undo${educationExperience.id || 'new'}`}
                    size="compact"
                    kind="minimal"
                    onClick={() => {
                      undoRemoveEducationExperience(i);
                    }}
                  >
                    Undo
                  </Button>
                ) : (
                  <>
                    {[
                      'institution_name',
                      'degree_type',
                      'major',
                      'graduation_date',
                      'notes',
                    ].map((educationExperienceField) =>
                      getEditField({
                        field: educationExperienceField,
                        fieldPath: [field, i],
                        validationMethod:
                          teValidationFields[educationExperienceField],
                      })
                    )}
                    <Button
                      fullWidth
                      key={`remove${educationExperience.id || 'new'}`}
                      size="compact"
                      kind="minimal"
                      onClick={() => {
                        removeEducationExperience(i);
                      }}
                      style={{
                        color: theme.colors.bodyCopyGray,
                        fontSize: '12px',
                        display: 'flex',
                        columnGap: '4px',
                      }}
                    >
                      <TrashIcon size="20" /> Remove
                    </Button>
                  </>
                )}
              </ExperienceEditWrapper>
            ))}
            <Button
              fullWidth
              kind="outline"
              size="compact"
              onClick={() => {
                addEducationExperience();
              }}
            >
              <PlusIcon style={{ marginRight: '8px' }} />
              {fieldValue.length === 0
                ? 'Add education'
                : 'Add another education'}
            </Button>
          </React.Fragment>
        );
      case 'company_name':
      case 'first_name':
      case 'institution_name':
      case 'job_title':
      case 'last_name':
      case 'major':
      case 'phone':
        return (
          <div key={field}>
            <Input
              error={hasError}
              label={getFieldLabel(field)}
              caption={error}
              placeholder={placeholderFieldsConfig[field] || ''}
              value={fieldValue || ''}
              onChange={(evt) => {
                const inputValue = evt.currentTarget.value;
                updateModifiedField({
                  inputValue,
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
              onBlur={(evt) => {
                if (validationMethod) {
                  const val = evt.target.value;
                  const validationRes = validationFunctions[validationMethod](
                    val,
                    field,
                    fieldPath,
                    talentProfile,
                    modifiedFields
                  );

                  if (validationRes.error) {
                    addError(field, validationRes.error, fieldPath);
                  } else {
                    clearError(field, fieldPath);

                    if (validationRes.updated_val) {
                      updateModifiedField({
                        inputValue: validationRes.updated_val,
                        field,
                        fieldValue,
                        fieldPath,
                      });
                    }
                  }
                }
              }}
              className={hasError ? 'has-error' : ''}
            />
          </div>
        );
        break;
      case 'email':
        return (
          <div key={field}>
            <Input
              value={fieldValue}
              disabled
              caption="Contact your talent advocate to update your email address."
            />
          </div>
        );
      case 'location':
        return (
          <div key={field}>
            <Select
              className={hasError ? 'has-error' : ''}
              data-testid={field}
              placeholder='Location e.g. "Detroit, MI"'
              value={fieldValue ? [{ label: fieldValue }] : []}
              instanceId={field}
              options={locationSuggestions}
              onBlur={() => {
                const validationRes = validationFunctions[validationMethod](
                  !fieldValue || fieldValue.length === 0 ? null : fieldValue,
                  field,
                  fieldPath,
                  talentProfile,
                  modifiedFields
                );
                if (validationRes.error) {
                  addError(field, validationRes.error, fieldPath);
                }
                setLocationSuggestions([]);
              }}
              maxDropdownHeight="85vh"
              onInputChange={handleLocationInputChange}
              onChange={({ value }) => {
                const newLocation = value.length === 0 ? null : value[0].label;
                const validationRes = validationFunctions[validationMethod](
                  newLocation,
                  field,
                  fieldPath,
                  talentProfile,
                  modifiedFields
                );

                if (validationRes.error) {
                  addError(field, validationRes.error, fieldPath);
                } else {
                  clearError(field, fieldPath);
                }

                updateModifiedField({
                  inputValue: newLocation,
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
              error={!!error}
              caption={error}
              overrides={selectOverrides}
            />
          </div>
        );
      case 'start_date':
      case 'end_date':
      case 'graduation_date': {
        const monthYear = getMonthYearFromDate(fieldValue);
        let isCurrentlyThere = false;
        if (field === 'end_date') {
          isCurrentlyThere = getFieldValue(
            'currently_there',
            fieldPath,
            talentProfile,
            modifiedFields
          );
        }

        return (
          <div className={hasError ? 'has-error' : ''} key={field}>
            <MonthPicker
              value={monthYear || undefined}
              label={getFieldLabel(field)}
              min={`${minYear}-01`}
              max={`${maxYear}-12`}
              error={hasError}
              onChange={(date) => {
                const newMonthYear = getMonthYearFromDate(date);
                let formattedDate = null;
                if (newMonthYear) {
                  formattedDate = `${newMonthYear}-01`;
                }
                if (validationMethod && !isCurrentlyThere) {
                  handleDatePickerValidation({
                    date,
                    validationMethod,
                    field,
                    fieldPath,
                    tp: talentProfile,
                    modifiedFieldsObj: modifiedFields,
                  });
                }
                updateModifiedField({
                  inputValue: formattedDate,
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
              disabled={isCurrentlyThere}
              caption={error}
            />
          </div>
        );
      }
      case 'currently_there':
        return (
          <Checkbox
            key={field}
            checked={fieldValue}
            onChange={() => {
              const inputValue = !fieldValue;
              updateModifiedField({
                inputValue,
                field,
                fieldValue,
                fieldPath,
              });

              clearError('start_date', fieldPath);
              clearError('end_date', fieldPath);

              const dateValidationMethod = 'startDateEndDateValid';
              const startDateVal = getFieldValue(
                'start_date',
                fieldPath,
                talentProfile,
                modifiedFields
              );
              handleDatePickerValidation({
                date: startDateVal,
                validationMethod: dateValidationMethod,
                field: 'start_date',
                fieldPath,
                tp: talentProfile,
                modifiedFieldsObj: modifiedFields,
              });

              if (inputValue === false) {
                const endDateVal = getFieldValue(
                  'end_date',
                  fieldPath,
                  talentProfile,
                  modifiedFields
                );
                handleDatePickerValidation({
                  date: endDateVal,
                  validationMethod: dateValidationMethod,
                  field: 'end_date',
                  fieldPath,
                  tp: talentProfile,
                  modifiedFieldsObj: modifiedFields,
                });
              }
            }}
          >
            I currently work here
          </Checkbox>
        );
      case 'duties':
      case 'notes':
        AiResponsibilities = (
          <InnerCard
            $hasError={hasError}
            className={hasError ? 'has-error' : ''}
          >
            {hasError && <SectionDataErrorText>{error}</SectionDataErrorText>}
            <InnerCardTextGroup>
              <Text variant="LabelMediumBold">
                Responsibilities and achievements
              </Text>
              {!fieldValue && !removedDuty && (
                <Text variant="ParagraphSmall">
                  Get over writers block using the magic of AI to generate a
                  description from your job title above
                </Text>
              )}
            </InnerCardTextGroup>
            {(fieldValue || removedDuty) && (
              <DutiesWrapper>
                {splitWorkExperienceDuties(fieldValue).map((duty, index) => (
                  <React.Fragment key={duty}>
                    {removedDuty?.index === index && (
                      <Button
                        kind="tertiary"
                        color="neutral"
                        onClick={() => {
                          setRemovedDuty(null);
                          updateModifiedField({
                            inputValue: fieldValue
                              .concat('\n\n', removedDuty.duty)
                              .trim(),
                            field,
                            fieldValue,
                            fieldPath,
                          });
                          trackEvent(
                            'talent.resume_editor_work_experience_duty.undo',
                            {
                              'Undo Remove Duty': removedDuty.duty,
                            }
                          );
                          if (hasError) {
                            clearError(field, fieldPath);
                          }
                        }}
                      >
                        <Text variant="ParagraphSmall" color="primary">
                          <DutiesButtonText>Undo</DutiesButtonText>
                        </Text>
                      </Button>
                    )}
                    <Button key={duty} kind="tertiary">
                      <DutiesButtonText
                        onClick={() => {
                          setResponsibilityText(duty);
                          setResumeResponsibilitiesModalIdOpen(fieldObj.id);
                          trackEvent(
                            'talent.resume_editor_work_experience_duty.edit',
                            {
                              'Edit Duty': duty,
                            }
                          );
                        }}
                      >
                        <Text variant="ParagraphSmall" color="grayLabel">
                          {duty}
                        </Text>
                      </DutiesButtonText>
                      <DutiesActions>
                        <PencilIcon
                          onClick={() => {
                            setResponsibilityText(duty);
                            setResumeResponsibilitiesModalIdOpen(fieldObj.id);
                            trackEvent(
                              'talent.resume_editor_work_experience_duty.edit',
                              {
                                'Edit Duty': duty,
                              }
                            );
                          }}
                        />
                        <TrashIcon
                          onClick={() => {
                            setRemovedDuty({ duty, index });
                            updateModifiedField({
                              inputValue: fieldValue
                                .replace(duty, '')
                                .replace('\n\n\n', '\n\n')
                                .trim(),
                              field,
                              fieldValue,
                              fieldPath,
                            });
                            trackEvent(
                              'talent.resume_editor_work_experience_duty.remove',
                              {
                                'Removed Duty': duty,
                              }
                            );
                          }}
                        />
                      </DutiesActions>
                    </Button>
                  </React.Fragment>
                ))}
                {removedDuty?.index >
                  splitWorkExperienceDuties(fieldValue).length - 1 && (
                  <Button
                    kind="tertiary"
                    color="neutral"
                    onClick={() => {
                      setRemovedDuty(null);
                      updateModifiedField({
                        inputValue: fieldValue
                          .concat('\n\n', removedDuty.duty)
                          .trim(),
                        field,
                        fieldValue,
                        fieldPath,
                      });
                      trackEvent(
                        'talent.resume_editor_work_experience_duty.undo',
                        {
                          'Undo Removed Duty': removedDuty.duty,
                        }
                      );
                      if (hasError) {
                        clearError(field, fieldPath);
                      }
                    }}
                  >
                    <Text variant="ParagraphSmall" color="primary">
                      <DutiesButtonText>Undo</DutiesButtonText>
                    </Text>
                  </Button>
                )}
              </DutiesWrapper>
            )}

            <Button
              size="compact"
              disabled={
                !getFieldValue(
                  'job_title',
                  fieldPath,
                  talentProfile,
                  modifiedFields
                )
              }
              onClick={() => {
                setResponsibilityText(null);
                setResumeResponsibilitiesModalIdOpen(fieldObj.id);
                trackEvent(
                  'talent.resume_editor_work_experience_duty.abracadabra_add',
                  {
                    'Add duty for work experience': fieldObj.id,
                  }
                );
              }}
            >
              {fieldValue
                ? 'Abracadabra another highlight'
                : 'Abracadabra my work highlights'}{' '}
              <WandIcon style={{ marginLeft: theme.sizing.unit8 }} />
            </Button>
            {!fieldValue && <Text variant="LabelXSmallBold">OR</Text>}
            <Button
              kind="minimal"
              size="mini"
              onClick={() => {
                setResponsibilityText('');
                setResumeResponsibilitiesModalIdOpen(fieldObj.id);
                trackEvent(
                  'talent.resume_editor_work_experience_duty.manual_add',
                  {
                    'Add duty for work experience': fieldObj.id,
                  }
                );
              }}
            >
              {fieldValue
                ? 'Add another highlight manually'
                : 'Add work highlights manually'}
            </Button>
            <TalentProfileResumeResponsibilitiesModal
              isOpen={resumeResponsibilitiesModalIdOpen === fieldObj.id}
              defaultResponsibilityText={responsibilityText}
              onClose={() => {
                setResponsibilityText(null);
                setResumeResponsibilitiesModalIdOpen(null);
              }}
              onAddHighlight={(inputValue) => {
                const duties = getFieldValue(
                  'duties',
                  fieldPath,
                  talentProfile,
                  modifiedFields
                );
                if (responsibilityText && responsibilityText.length > 0) {
                  updateModifiedField({
                    inputValue: duties.replace(responsibilityText, inputValue),
                    field,
                    fieldValue,
                    fieldPath,
                  });
                } else {
                  updateModifiedField({
                    inputValue: `${duties}\n${inputValue}`,
                    field,
                    fieldValue,
                    fieldPath,
                  });
                }
                if (hasError) {
                  clearError(field, fieldPath);
                }
              }}
              jobTitle={getFieldValue(
                'job_title',
                fieldPath,
                talentProfile,
                modifiedFields
              )}
            />
          </InnerCard>
        );
        return (
          <React.Fragment key={field}>
            {field === 'duties' &&
              isTalentResumeWizardAiResponsibilities &&
              AiResponsibilities}
            {(field !== 'duties' ||
              !isTalentResumeWizardAiResponsibilities) && (
              <TextAreaInputWrapper className={hasError ? 'has-error' : ''}>
                <TextAreaTitleWrapper>
                  <Text variant="DisplaySmall" element="strong">
                    {field === 'duties'
                      ? 'Description of responsibilities'
                      : 'Additional notes about your education'}
                  </Text>
                </TextAreaTitleWrapper>
                <Textarea
                  error={hasError}
                  caption={error}
                  label={getFieldLabel(field)}
                  value={fieldValue}
                  onBlur={(evt) => {
                    if (validationMethod) {
                      const val = evt.target.value;
                      const validationRes = validationFunctions[
                        validationMethod
                      ](val, field, fieldPath, talentProfile, modifiedFields);
                      if (validationRes.error) {
                        addError(field, validationRes.error, fieldPath);
                      } else {
                        clearError(field, fieldPath);
                      }
                    }
                  }}
                  onChange={(evt) => {
                    const inputValue = evt.currentTarget.value;
                    updateModifiedField({
                      inputValue,
                      field,
                      fieldValue,
                      fieldPath,
                    });
                  }}
                />
              </TextAreaInputWrapper>
            )}
          </React.Fragment>
        );
      case 'degree_type':
        // TODO need Select to show error format when hasError is true
        return (
          <div key={field}>
            <Select
              placeholder="Select degree type"
              value={degreeTypeOptions.filter(
                (option) => option.value === fieldValue
              )}
              valueKey="value"
              options={degreeTypeOptions}
              clearable={false}
              onChange={(evt) => {
                const inputValue =
                  evt.value.length > 0 ? evt.value[0].value : '';
                if (validationMethod) {
                  validationFunctions[validationMethod](
                    inputValue,
                    field,
                    fieldPath
                  );
                }
                updateModifiedField({
                  inputValue,
                  field,
                  fieldValue,
                  fieldPath,
                });
                if (hasError) {
                  clearError(field, fieldPath);
                }
              }}
              className={hasError ? 'has-error' : ''}
              error={hasError}
              caption={error}
              overrides={selectOverrides}
              instanceId={field}
            />
          </div>
        );
      case 'state_licenses':
        return (
          <Fragment key={field}>
            <Text variant="LabelXSmallCapBold">Active Licenses</Text>
            <ActiveLicenses
              key={field}
              licenseStates={fieldValue.map((stateLicense) => {
                const stateDetails = statesOrderedMap.get(
                  stateLicense.state_id
                );
                return {
                  ...stateDetails,
                  state_id: stateLicense.state_id,
                  hasActiveLicense: true,
                  hasCompactLicense: stateLicense.compact,
                };
              })}
              setLicenseStates={(e) => {
                const newLicenseStates = e.map((licenseState) => ({
                  state_id: licenseState.id,
                  compact: licenseState.hasCompactLicense,
                }));
                updateModifiedField({
                  inputValue: newLicenseStates,
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
              stateOptions={states.map((state) => ({
                ...state,
                disabled: fieldValue.some(
                  // eslint-disable-next-line react/prop-types
                  (licenseState) => licenseState.state_id === state.id
                ),
                // eslint-disable-next-line react/prop-types
                label: state.name,
              }))}
              CompactAction={CompactAction}
              StateAction={StateAction}
            />
          </Fragment>
        );
      case 'certifications':
        return (
          <Fragment key={field}>
            <Text variant="LabelXSmallCapBold">Certifications</Text>
            <CertificationsEditor
              key={field}
              certifications={fieldValue ? fieldValue.split(', ') : []}
              updateCertifications={(newCertifications) => {
                updateModifiedField({
                  inputValue: newCertifications.join(', '),
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
            />
          </Fragment>
        );
      case 'resume_advanced_licenses': {
        return (
          <Fragment key={field}>
            <Text variant="LabelXSmallCapBold">Advanced Licenses</Text>
            <ButtonSelector
              options={constants.ADVANCED_LICENSE_OPTIONS}
              values={fieldValue}
              updateValues={(newAdvancedLicenses: string[]) => {
                updateModifiedField({
                  inputValue: newAdvancedLicenses,
                  field,
                  fieldValue,
                  fieldPath,
                });
              }}
            />
          </Fragment>
        );
      }
      case 'resume_np_specialties': {
        const advancedLicenses = getFieldValue(
          'resume_advanced_licenses',
          fieldPath,
          talentProfile,
          modifiedFields
        );
        const hasNPLicense = advancedLicenses.includes('NP');
        if (!hasNPLicense && fieldValue.length > 0) {
          updateModifiedField({
            inputValue: [],
            field,
            fieldValue,
            fieldPath,
          });
        }

        return (
          <Fragment key={field}>
            <Hideable hidden={!hasNPLicense}>
              <Text variant="LabelXSmallCapBold">NP Specialties</Text>
            </Hideable>
            <Hideable hidden={!hasNPLicense}>
              <ButtonSelector
                options={constants.NP_SPECIALTY_OPTIONS}
                values={fieldValue}
                updateValues={(newNpSpecialties: string[]) => {
                  updateModifiedField({
                    inputValue: newNpSpecialties,
                    field,
                    fieldValue,
                    fieldPath,
                  });
                }}
              />
            </Hideable>
          </Fragment>
        );
      }

      case 'work_experience_specialties':
        /* TODO - handle specialties later, they're not on our generated resumes
        const selectedSpecialties = fieldValue
          ? fieldValue.map(
              (wes) =>
                nurseSpecialtyDescriptionsMap[
                  wes.nurse_specialty_description_id
                ]
            )
          : [];
        return (
          <Select
            key={field}
            value={selectedSpecialties}
            multi
            labelKey="description"
            options={Object.values(nurseSpecialtyDescriptionsMap)}
          />
        );
         */
        return null;
      default:
        console.error(`Unsupported field: ${field} - ${fieldValue}`);
        return null;
    }
  };

  const editFields = fields.map((field) =>
    getEditField({ field, validationMethod: rootValidationFields[field] })
  );
  const editSectionRef = useRef(null);
  const hasModifiedFields = Object.keys(modifiedFields).length > 0;

  return (
    <EditSectionWrapper ref={editSectionRef}>
      <ActionButtonWrapper>
        <Button
          data-testid="close-edit-modal-button"
          kind="minimal"
          disabled={isSaving}
          onClick={() => {
            setIsEditing(null);
            trackEvent('talent.resume_editor_modal_edit.cancel', {
              'Section Title': title,
            });
          }}
        >
          {hasModifiedFields ? 'Cancel' : 'Close'}
        </Button>
        <Button
          disabled={isSaving || !hasModifiedFields}
          isLoading={isSaving}
          onClick={() => {
            if (hasModifiedFields) {
              const allErrors = validateAllFields({
                fieldObj: { ...talentProfile, ...modifiedFields },
              });

              setErrors({ ...allErrors });

              let hasErrors = false;

              if (allErrors) {
                hasErrors = Object.values(allErrors).some(
                  (value) => typeof value === 'string'
                );

                if (fieldSection) {
                  hasErrors = allErrors[fieldSection].length > 0;
                } else {
                  hasErrors = editFields
                    .map((editField) => editField.key)
                    .some(
                      (editFieldKey) => get(allErrors, editFieldKey) != null
                    );
                }
              }

              if (hasErrors) {
                setTimeout(() => {
                  const section = document.querySelector('.has-error');
                  if (section) {
                    section.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                  }
                }, 100);

                return;
              }

              const modifiedFieldsToSend: GenericObject = {
                ...modifiedFields,
              };
              if (modifiedFieldsToSend.work_experiences) {
                modifiedFieldsToSend.work_experiences_attributes =
                  modifiedFieldsToSend.work_experiences
                    .map((work_experience) => {
                      // eslint-disable-next-line no-underscore-dangle
                      if (work_experience._destroy) {
                        if (!work_experience.id) {
                          return null;
                        }
                        return {
                          id: work_experience.id,
                          _destroy: true,
                        };
                      }

                      if (!work_experience.isTouched) {
                        return null;
                      }

                      const cleanWorkExperience = {
                        ...work_experience,
                      };
                      if (cleanWorkExperience.currently_there) {
                        cleanWorkExperience.end_date = null;
                      }
                      delete cleanWorkExperience.currently_there;
                      delete cleanWorkExperience.isTouched;
                      return cleanWorkExperience;
                    })
                    .filter((work_experience) => work_experience !== null);

                if (
                  modifiedFieldsToSend.work_experiences_attributes.length === 0
                ) {
                  delete modifiedFieldsToSend.work_experiences_attributes;
                }

                delete modifiedFieldsToSend.work_experiences;
              }
              if (modifiedFieldsToSend.talent_education_experiences) {
                modifiedFieldsToSend.talent_education_experiences_attributes =
                  modifiedFieldsToSend.talent_education_experiences
                    .map((educationExperience) => {
                      if (educationExperience._destroy) {
                        if (!educationExperience.id) {
                          return null;
                        }
                        return {
                          id: educationExperience.id,
                          _destroy: true,
                        };
                      }

                      if (!educationExperience.isTouched) {
                        return null;
                      }

                      return educationExperience;
                    })
                    .filter(
                      (educationExperience) => educationExperience !== null
                    );
                if (
                  modifiedFieldsToSend.talent_education_experiences_attributes
                    .length === 0
                ) {
                  delete modifiedFieldsToSend.talent_education_experiences_attributes;
                }

                delete modifiedFieldsToSend.talent_education_experiences;
              }
              if (modifiedFieldsToSend.state_licenses) {
                modifiedFieldsToSend.state_licenses_attributes =
                  modifiedFieldsToSend.state_licenses.map((stateLicense) => {
                    const hasCompactLicenseField =
                      Object.prototype.hasOwnProperty.call(
                        stateLicense,
                        'hasCompactLicense'
                      );
                    if (!hasCompactLicenseField) {
                      return stateLicense;
                    }
                    const modifiedStateLicense = { ...stateLicense };
                    if (hasCompactLicenseField) {
                      modifiedStateLicense.compact =
                        modifiedStateLicense.hasCompactLicense;
                      delete modifiedStateLicense.hasCompactLicense;
                    }
                    return modifiedStateLicense;
                  });
                delete modifiedFieldsToSend.state_licenses;
              }

              doUpdateTalentProfile({
                talentProfileId: talentProfile.id,
                body: modifiedFieldsToSend,
              });
            }
          }}
        >
          {next ? 'Next' : 'Save'}
        </Button>
      </ActionButtonWrapper>
      <SectionTitle>{title}</SectionTitle>
      <EditFieldsWrapper>{editFields}</EditFieldsWrapper>
    </EditSectionWrapper>
  );
};

EditSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  next: PropTypes.object,
  setIsEditing: PropTypes.func.isRequired,
  talentProfile: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  updateTalentProfileCompleteAction: PropTypes.func.isRequired,
};

EditSection.defaultProps = {
  next: null,
};

const qualificationsEditFields = {
  title: 'Qualifications',
  fields: [
    'certifications',
    'state_licenses',
    'resume_advanced_licenses',
    'resume_np_specialties',
  ],
};

const educationExperienceEditFields = {
  title: 'Education Experience',
  fields: ['talent_education_experiences'],
  next: qualificationsEditFields,
};

const workExperienceEditFields = {
  title: 'Work experience',
  fields: ['work_experiences'],
  next: educationExperienceEditFields,
};

const contactEditFields = {
  title: 'Contact information',
  fields: ['first_name', 'last_name', 'phone', 'email'],
  next: workExperienceEditFields,
};

const editHashMappings = {
  '#resume/contact': contactEditFields,
  '#resume/education': educationExperienceEditFields,
  '#resume/work-experience': workExperienceEditFields,
  '#resume/qualifications': qualificationsEditFields,
};

const ContactInformation = ({
  editAction,
  talentProfile,
  validationErrors = null,
}) => (
  <ResumeSection onClick={editAction}>
    <SectionTitle $noHorizontalPadding>
      Contact information <PencilIcon />
    </SectionTitle>
    <SectionData>
      <strong>{talentProfile.full_name}</strong>
    </SectionData>
    <SectionData
      error={get(validationErrors, 'phone') != null}
      errorText="Missing phone number"
    >
      {talentProfile.phone.text}
    </SectionData>
    <SectionData>{talentProfile.email}</SectionData>
  </ResumeSection>
);
ContactInformation.propTypes = {
  editAction: PropTypes.func.isRequired,
  talentProfile: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

const colorOptions = [
  { label: 'Slate', value: 'slate' },
  { label: 'Grape', value: 'grape' },
  { label: 'Rust', value: 'rust' },
  { label: 'Kale', value: 'kale' },
  { label: 'Ocean', value: 'ocean' },
];

const errorMsg =
  'Oops, there was a problem connecting ' +
  'to the server. Please check your internet connection ' +
  'and try again, or refresh the page.';

const ColorTheme = ({
  dispatch,
  talentProfile,
  updateTalentProfileCompleteAction,
}) => {
  const { mutate: doUpdateTalentProfile, isLoading: isSaving } =
    useUpdateTalentProfile({
      onSuccess: (resp) => {
        dispatch(updateTalentProfileCompleteAction(resp));
        trackEvent('talent.resume_editor_modal_edit_color', {
          'Resume Color': resp.resume_color,
        });
      },
      onError: (err) => {
        console.error('Failed to save resume details', err);
        toaster.negative(errorMsg);
      },
    });

  return (
    <ResumeSection>
      <SectionTitle $noHorizontalPadding>Template customization</SectionTitle>
      <SectionData>
        <Text variant="LabelXSmallCapBold" color="bodyCopyGray">
          Choose a color theme
        </Text>
        <ColorThemeFieldContainer>
          <ColorThemeFieldWrapper>
            <ButtonSelect
              buttonComponent={ColorThemeButton}
              radioSelect
              orientation="horizontal"
              options={colorOptions}
              onChange={(value) =>
                doUpdateTalentProfile({
                  talentProfileId: talentProfile.id,
                  body: {
                    resume_color: value,
                  },
                })
              }
              selectedOptions={colorOptions.filter(
                (option) => option.value === talentProfile.resume_color
              )}
            />
          </ColorThemeFieldWrapper>
          <ColorThemeFieldLabelWrapper>
            {isSaving ? (
              <Spinner $size="small" />
            ) : (
              <Text variant="LabelXSmall" color="grayLabel">
                {capitalize(talentProfile.resume_color)}
              </Text>
            )}
          </ColorThemeFieldLabelWrapper>
        </ColorThemeFieldContainer>
      </SectionData>
    </ResumeSection>
  );
};

ColorTheme.propTypes = {
  dispatch: PropTypes.func.isRequired,
  talentProfile: PropTypes.object.isRequired,
  updateTalentProfileCompleteAction: PropTypes.func.isRequired,
};

const WorkExperience = ({
  editAction,
  // nurseSpecialtyDescriptionsMap,
  talentProfile,
  validationErrors = null,
}) => (
  <ResumeSection onClick={editAction}>
    <SectionTitle $noHorizontalPadding>
      Work experience <PencilIcon />
    </SectionTitle>
    <SectionData
      error={get(validationErrors, [
        'resume_generation_errors',
        'work_experiences_empty',
      ])}
      errorText="Missing work experience"
    />
    {talentProfile.work_experiences &&
      talentProfile.work_experiences.map((workExperience, index) => {
        const startDateText = getMonthTextFromDate(workExperience.start_date);
        const endDateText = getMonthTextFromDate(workExperience.end_date);

        return (
          <ExperienceWrapper key={workExperience.id}>
            <SectionData>
              <strong>
                {workExperience.job_title && `${workExperience.job_title} at `}{' '}
                {workExperience.company_name}
              </strong>
            </SectionData>
            <SectionData>
              {startDateText} - {endDateText || 'Present'}
            </SectionData>
            <SectionData
              error={
                get(validationErrors, [
                  'work_experiences',
                  index,
                  'location',
                ]) === 'Required'
              }
              errorText="Missing location"
            />
            <SectionData
              error={
                get(validationErrors, ['work_experiences', index, 'duties']) ===
                'Required'
              }
              errorText="Missing description of responsibilities"
            >
              <ExperienceDescription>
                <ul>
                  {splitWorkExperienceDuties(workExperience.duties).map(
                    (duty) => (
                      <li key={duty}>{duty}</li>
                    )
                  )}
                </ul>
              </ExperienceDescription>
            </SectionData>
            {/* TODO - handle specialties later, they're not on our generated resumes  */}
            {/* <SectionData> */}
            {/*   {workExperience.work_experience_specialties.map( */}
            {/*     (work_experience) => ( */}
            {/*       <Tag key={work_experience.id} closeable={false}> */}
            {/*         { */}
            {/*           nurseSpecialtyDescriptionsMap[ */}
            {/*             work_experience.nurse_specialty_description_id */}
            {/*           ].description */}
            {/*         } */}
            {/*       </Tag> */}
            {/*     ) */}
            {/*   )} */}
            {/* </SectionData> */}
          </ExperienceWrapper>
        );
      })}
  </ResumeSection>
);
WorkExperience.propTypes = {
  editAction: PropTypes.func.isRequired,
  // nurseSpecialtyDescriptionsMap: PropTypes.object.isRequired,
  talentProfile: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

const EducationExperience = ({
  editAction,
  talentProfile,
  validationErrors = null,
}) => (
  <ResumeSection onClick={editAction}>
    <SectionTitle $noHorizontalPadding>
      Educational experience <PencilIcon />
    </SectionTitle>
    <SectionData
      error={get(validationErrors, [
        'resume_generation_errors',
        'talent_education_experiences_empty',
      ])}
      errorText="Missing educational experience"
    />

    {talentProfile.talent_education_experiences &&
      talentProfile.talent_education_experiences.map((educationExperience) => {
        const graduationDateText = getMonthTextFromDate(
          educationExperience.graduation_date
        );

        return (
          <ExperienceWrapper key={educationExperience.id}>
            <SectionData>
              <strong>
                {degreeTypeMap[educationExperience.degree_type]} -{' '}
                {educationExperience.major}
              </strong>
            </SectionData>
            <SectionData>{educationExperience.institution_name}</SectionData>
            <SectionData>{graduationDateText}</SectionData>
            <SectionData>
              <ExperienceDescription>
                {educationExperience.notes}
              </ExperienceDescription>
            </SectionData>
          </ExperienceWrapper>
        );
      })}
  </ResumeSection>
);
EducationExperience.propTypes = {
  editAction: PropTypes.func.isRequired,
  talentProfile: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

const Qualifications = ({ editAction, talentProfile, statesOrderedMap }) => {
  const [, theme] = useStyletron();
  return (
    <ResumeSection onClick={editAction}>
      <SectionTitle $noHorizontalPadding>
        Qualifications <PencilIcon />
      </SectionTitle>
      <SectionData>
        <QualificationsSetWrapper>
          <QualificationsSet>
            <strong>Certifications</strong>
            <QualificationsList>
              {(talentProfile.certifications || '')
                .split(',')
                .map((certification) => (
                  <li key={certification}>{certification}</li>
                ))}
            </QualificationsList>
          </QualificationsSet>
          <QualificationsSet>
            <strong>Licensed</strong>
            <QualificationsList>
              {talentProfile.state_licenses.map((license) => {
                const stateDetails = statesOrderedMap.get(license.state_id);
                return (
                  <li key={license.state_id}>
                    RN - {stateDetails.name}{' '}
                    {license.compact ? (
                      <Tag
                        closeable={false}
                        kind="custom"
                        color={theme.colors.positive}
                        $textColor={theme.colors.white}
                        variant="solid"
                        size="tiny"
                      >
                        COMPACT
                      </Tag>
                    ) : null}
                  </li>
                );
              })}
              {talentProfile.resume_advanced_licenses.map((license) => {
                if (license === 'NP') return null;
                return <li key={license}>{license}</li>;
              })}
              {talentProfile.resume_np_specialties.map((license) => (
                <li key={license}>NP - {license}</li>
              ))}
            </QualificationsList>
          </QualificationsSet>
        </QualificationsSetWrapper>
      </SectionData>
    </ResumeSection>
  );
};
Qualifications.propTypes = {
  editAction: PropTypes.func.isRequired,
  statesOrderedMap: PropTypes.object.isRequired,
  talentProfile: PropTypes.object.isRequired,
};

// TODO: Replace the redux-action types and API responses
// with real types
interface ResumeModalProps {
  talentProfile: TalentProfile | null;
  dispatch: (action: any) => void;
  reportInitialDisplay(isOpen: boolean): void;
  updateTalentProfileCompleteAction: (response: any) => any;
}

function ResumeModal({
  dispatch,
  reportInitialDisplay,
  talentProfile = null,
  updateTalentProfileCompleteAction,
}: ResumeModalProps) {
  const { hash } = useLocation();
  const history = useHistory();

  const updateHash = (newHash) => {
    history.push(`${history.location.pathname}${newHash}`);
  };
  const [isEditing, setIsEditing] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [closeAfterEdit, setCloseAfterEdit] = useState(false);
  const { states: statesOrderedMap } = useContext(AppContext) as any;

  const isOpen = hash.startsWith(MODAL_PATH);
  const featureFlags = useAppContextFeatureFlags();

  const onDismiss = () => {
    updateHash('');
    trackEvent('talent.resume_editor_modal.close');
  };
  // const { nurse_specialty_descriptions } = useContext(AppContext) as any;

  const shouldEmailResume = !(
    talentProfile &&
    talentProfile.hiring_activated_at &&
    talentProfile.screener_connected
  );
  const readyHash = shouldEmailResume ? '#resume_emailed' : '#resume_ready';

  const { mutate: doGenerateResume, isLoading: isGeneratingResume } =
    useGenerateResume({
      onSuccess: (resp) => {
        dispatch(updateTalentProfileCompleteAction(resp));
        trackEvent('talent.resume_editor_modal.generate_resume');
        onDismiss();
        updateHash(readyHash);
      },
      onError: (err: { message: string }) => {
        console.error({ err });
        toaster.negative(err.message);
      },
    });

  const isTalentResumeWizardAiResponsibilities =
    featureFlags &&
    featureFlags.includes('talent_resume_wizard_ai_responsibilities');

  useEffect(() => {
    if (isOpen) {
      trackComponentView('talent.resume_editor_modal.open', {
        'AI Responsibilities': isTalentResumeWizardAiResponsibilities,
      });

      const editKey = hash.replace(/-standalone/, '');
      if (isEditing === null && editHashMappings[editKey]) {
        if (hash.endsWith('-standalone')) {
          setCloseAfterEdit(true);
          setIsEditing({
            ...editHashMappings[editKey],
            next: null,
          });
        } else {
          setIsEditing(editHashMappings[editKey]);
        }
        updateHash('#resume');
      } else if (closeAfterEdit && isEditing === null) {
        setCloseAfterEdit(false);
        updateHash('');
      }
    }
  }, [talentProfile, hash, isOpen, isEditing, updateHash, closeAfterEdit]);

  useEffect(() => {
    reportInitialDisplay(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!talentProfile) {
    return null;
  }

  const validationErrors = validateAllFields({
    fieldObj: { ...talentProfile },
  });

  const preventResumeGeneration = Object.values(
    validationErrors.resume_generation_errors
  ).some((err) => !!err);

  return (
    <>
      <TalentModal
        useTalentDialog={false}
        onClose={onDismiss}
        closeable={false}
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.full}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: {
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Dialog: {
            style: {
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              overflow: 'auto',
            },
          },
        }}
      >
        <>
          <Wrapper>
            <ActionButtonWrapper>
              <Button
                kind="outline"
                onClick={() => {
                  onDismiss();
                }}
              >
                Close
              </Button>
              <Button
                kind="tertiary"
                color="neutral"
                padding="minimal"
                style={{
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  borderRadius: '24px',
                }}
                onClick={() => {
                  setIsPreviewOpen(true);
                }}
              >
                <GlassesIcon title="Preview" />
              </Button>
              <Button
                disabled={preventResumeGeneration || isGeneratingResume}
                isLoading={isGeneratingResume}
                onClick={() =>
                  doGenerateResume({
                    id: talentProfile.id,
                    queryParams: shouldEmailResume ? '?email_resume=true' : '',
                  })
                }
              >
                Generate
              </Button>
            </ActionButtonWrapper>
            <ResumeModalBody>
              {isTalentResumeWizardAiResponsibilities ? (
                <TitleSection>
                  <div>
                    <Title>Resume wizard</Title>
                    <SubTitle>
                      Create a professional resume harnessing the power of AI.
                    </SubTitle>
                  </div>
                  <TitleImage
                    src={resumeWizardIllustration}
                    alt="Resume Wizard"
                  />
                </TitleSection>
              ) : (
                <>
                  <Title>Resume wizard</Title>
                  <SubTitle>
                    Create a professional resume with our simple resume
                    generator
                  </SubTitle>
                </>
              )}
              <SectionDivider />
              <ColorTheme
                dispatch={dispatch}
                talentProfile={talentProfile}
                updateTalentProfileCompleteAction={
                  updateTalentProfileCompleteAction
                }
              />
              <SectionDivider />
              <ContactInformation
                editAction={() => {
                  setIsEditing(contactEditFields);
                }}
                talentProfile={talentProfile}
                validationErrors={validationErrors}
              />
              <SectionDivider />
              <WorkExperience
                editAction={() => {
                  setIsEditing(workExperienceEditFields);
                }}
                // nurseSpecialtyDescriptionsMap={nurseSpecialtyDescriptionsMap}
                talentProfile={talentProfile}
                validationErrors={validationErrors}
              />
              <SectionDivider />
              <EducationExperience
                editAction={() => {
                  setIsEditing(educationExperienceEditFields);
                }}
                talentProfile={talentProfile}
                validationErrors={validationErrors}
              />
              <SectionDivider />
              <Qualifications
                editAction={() => {
                  setIsEditing(qualificationsEditFields);
                }}
                statesOrderedMap={statesOrderedMap}
                talentProfile={talentProfile}
              />
            </ResumeModalBody>
            <Drawer
              isOpen={isPreviewOpen}
              onClose={() => setIsPreviewOpen(false)}
              anchor={ANCHOR.bottom}
            >
              <PreviewIframe
                src={`/talent_profile/${talentProfile.id}/preview_resume`}
              />
            </Drawer>
          </Wrapper>
        </>
      </TalentModal>
      <TalentModal
        useTalentDialog={false}
        onClose={() => {
          setIsEditing(null);
        }}
        isOpen={isEditing}
        animate
        autoFocus
        size={SIZE.full}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: {
              textAlign: 'center',
            },
          },
          Close: {
            style: () => ({
              display: 'none',
            }),
          },
          Dialog: {
            style: {
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: '10px',
              paddingRight: '10px',
              paddingBottom: '10px',
              paddingLeft: '10px',
            },
          },
        }}
      >
        <Wrapper>
          {isEditing && (
            <EditSection
              dispatch={dispatch}
              talentProfile={clone(talentProfile)}
              setIsEditing={setIsEditing}
              updateTalentProfileCompleteAction={
                updateTalentProfileCompleteAction
              }
              // nurseSpecialtyDescriptionsMap={nurseSpecialtyDescriptionsMap}
              {...isEditing}
            />
          )}
        </Wrapper>
      </TalentModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  talentProfile: state.talent_profile,
});

export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  updateTalentProfileCompleteAction: updateTalentProfileComplete,
}))(ResumeModal);
