import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import qs from 'qs';

import AppProvider from 'shared/components/AppProvider';
import { TalentToasterContainer } from 'shared/components/ihcl/toast';
import { trackAppLoad } from 'shared/helpers/tracking';
import withTracking from 'shared/helpers/withTracking';

import EmployerForm from '../components/EmployerForm';
import SignXForm from '../components/non_hiring/SignXForm';
import NonHiringContainer from '../containers/NonHiringContainer';
import SignUpNavTabContainer from '../containers/SignUpNavTabContainer';
import TalentOnboardContainer from '../containers/TalentOnboardContainer';
import TalentOnboardLiteContainer, {
  nonHiringIntentRegex,
} from '../containers/TalentOnboardLiteContainer';
import TalentReactivationContainer from '../containers/TalentReactivationContainer';
import TalentProfileResumeModal from '../../talent/components/TalentProfileResumeModal';
import PopUpManager from '../../talent/components/PopUpManager';

trackAppLoad('Registration');

// eslint-disable-next-line react/require-default-props
const RegistrationApp = ({ ...props }) => (
  <AppProvider store={props.store} appContextKeys={props.appContextKeys}>
    <TalentToasterContainer />
    <BrowserRouter>
      <PopUpManager
        popUps={[[TalentProfileResumeModal, 'TalentProfileResumeModal']]}
        dispatch={props.store.dispatch}
      />

      <Switch>
        <Redirect exact from="/users/sign_up" to="/talent_onboard" />

        <Route
          // eslint-disable-next-line react/no-unstable-nested-components
          component={({ location }) => (
            // Preserve the query string during the redirect
            <Redirect to={{ ...location, pathname: '/talent_onboard' }} />
          )}
          path="/users/sign_up/talent"
        />

        <Route
          // eslint-disable-next-line react/no-unstable-nested-components
          component={withTracking(({ location }) => {
            const query = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            return (
              <SignUpNavTabContainer>
                <EmployerForm
                  initialErrors={props.errors}
                  previousData={props.resource}
                  urlEmail={query.email}
                />
              </SignUpNavTabContainer>
            );
          })}
          path="/users/sign_up/employer"
        />

        <Route
          // eslint-disable-next-line react/no-unstable-nested-components
          component={withTracking(({ match }) => (
            <NonHiringContainer>
              <SignXForm
                initialErrors={props.errors}
                intent={match.params[0]}
                isSignUp={false}
                previousData={props.resource}
              />
            </NonHiringContainer>
          ))}
          path={`/users/sign_in/${nonHiringIntentRegex}`}
        />

        <Route
          path={`/users/:type(sign_up|onboard)/:intent${nonHiringIntentRegex}/:funnelChar?`}
        >
          <TalentOnboardLiteContainer />
        </Route>

        <Route component={TalentOnboardContainer} path="/talent_onboard" />

        <Route
          component={TalentReactivationContainer}
          path="/talent_reactivation"
        />
      </Switch>
    </BrowserRouter>
  </AppProvider>
);

RegistrationApp.propTypes = {
  appContextKeys: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  resource: PropTypes.object,
  store: PropTypes.object.isRequired,
};

RegistrationApp.defaultProps = {
  appContextKeys: [],
  errors: null,
  resource: null,
};

export default hot(module)(RegistrationApp);
